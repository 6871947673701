import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER, BASE_URI_FILES } from '@/api/CONST.js';

/**
 * get homework
 * @param {number|string} activityId activity id
 * @returns Promise
 */
export const getHomeworkBlock = activityId => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}`);

/**
 *
 * @param {object} options
 * @param {*} options.homeworkId
 * @param {*} options.page
 * @param {*} options.search
 * @param {*} options.not_authorized
 * @param {*} options.new_message
 * @param {*} options.status
 * @param {Array<*>} options.mentors
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const getHomeworkBlockList = (options, config = {}) => {
    /* eslint-disable camelcase */
    const { homeworkId, page, search, not_authorized, new_message, status, mentors } = options;

    return axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${homeworkId}/students`, {
        ...config,
        params: {
            page,
            search,
            not_authorized: not_authorized ? 1 : 0,
            new_message: new_message ? 1 : 0,
            status,
            mentors,
        },
    });
    /* eslint-enable camelcase */
};

/**
 * @param {number|string} homeworkId
 * @param {number|string} homeworkId
 * @param {object} [options]
 * @param {number|string} [options.page]
 * @param {string} [options.search]
 * @param {object} [config]
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const getHomeworkBlockMentors = (homeworkId, options = {}, config = {}) => {
    const { page, search } = options;

    return axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${homeworkId}/mentors`, {
        ...config,
        params: {
            page,
            search,
        },
    });
};

/**
 * @param {object} options
 * @param {number|string} options.versionId
 * @param {number|string} [options.page]
 * @param {'HomeworkFiles' | 'EssayHomework'} [options.type]
 * @param {string} [options.acceptance]
 * @param {string[]} [options.assignments]
 * @param {'newest' | 'oldest'} [options.sort]
 * @returns {Promise<import('axios').AxiosResponse>} Promise
 */
export const getVersionHomeworkActivities = ({
    versionId,
    page,
    type,
    sort,
    acceptance,
    assignments,
}) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/versions/${versionId}/homework-activities`, {
    params: {
        page,
        type,
        sort,
        type_acceptance: acceptance,
        assignment_type: assignments,
    },
});

/**
 * Получить домашнее задание
 * @param activityId
 * @param studentId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchHomework = ({ activityId, studentId }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}`);

/**
 * Получить историю домашнего задания
 * @param activityId
 * @param studentId
 * @param id
 * @param cursor Хэш курсорной пагинации
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchHomeworkHistory = ({ activityId, studentId, cursor, order }) => axios
    .get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/history`, {
        params: {
            cursor,
            order,
        },
    });

/**
 * Начать сеccию проверки домашнего задания
 * @param activityId
 * @param studentId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const startHomeworkCheckingSession = ({ activityId, studentId }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/start-checking`);

/**
 * Закончить сеccию проверки домашнего заданияы
 * @param homeworkId
 * @param studentId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const finishHomeworkCheckingSession = ({ activityId, studentId }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/finish-checking`);

/**
 * Продлить сеccию проверки домашнего задания
 * @param homeworkId
 * @param studentId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const prolongHomeworkCheckingSession = ({ activityId, studentId }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/prolong-checking`);

/**
 * Получить сеccию проверки домашнего задания
 * @param activityId
 * @param studentId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const getHomeworkCheckingSession = ({ activityId, studentId }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/checking-session`);

/**
 * Получить элемент истории домашнего задания
 * @param activityId
 * @param studentId
 * @param historyId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchHomeworkHistoryItem = ({ activityId, studentId, historyId }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/history/${historyId}`);

/**
 * Создать ответ для домашнего задания
 * @param activityId
 * @param studentId
 * @param data
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const createHomeworkResponse = ({ activityId, studentId, data }) => axios.post(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/responses`,
    data,
);

/**
 * Обновить ответ домашнего задания
 * @param {object} options
 * @param {string|number} options.activityId
 * @param {string|number} options.studentId
 * @param {string|number} options.responseId
 * @param {object} options.data
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const updateHomeworkResponse = ({ activityId, studentId, responseId, data }) => axios.put(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/responses/${responseId}`,
    data,
);

/**
 * Отменить ответ домашнего задания и создать новый
 * @param activityId
 * @param studentId
 * @param responseId
 * @param data
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const cancelAndCreatHomeworkResponse = ({ activityId, studentId, responseId, data }) => axios.post(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/responses/${responseId}/cancel`,
    data,
);

/**
 * @param {object} params
 * @param {*} params.activityId
 * @param {*} params.studentId
 * @param {*} params.historyId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const homeworkHistoryViewed = ({ activityId, studentId, historyId }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/history/${historyId}/view`);

/**
 * Получить файл шаблона для импорта учеников в систему.
 * @param {*} activityId
 * @returns {string}
 */
export const getImportingStudentsTemplate = activityId => `${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/homework/import/template`;

/**
 * Импорт файла решений ДЗ.
 * @param {number|string} activityId activity id
 * @param {FormData} formData
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const importFileHomeworkSolutions = (activityId, formData) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/homework/import`, formData);

/**
 * Импорт файла решений ДЗ.
 * @param {number|string} activityId activity id
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const getStudentsReport = activityId => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/homework/report`);
/**
 * Создать ответ домашнего задания от преподавателя
 * @param activityId
 * @param studentId
 * @param data
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const createHomeworkAnswerByTeacher = ({ activityId, studentId, data }) => axios.post(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}}/students/${studentId}/versions`,
    data,
);

/**
 * Обновить ответ домашнего задания от преподавателя
 * @param {object} options
 * @param {string|number} options.activityId
 * @param {string|number} options.studentId
 * @param {string|number} options.versionId
 * @param {object} options.data
 * @param {string} [options.data.essay]
 * @param {array} [options.data.files]
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const updateHomeworkAnswerByTeacher = ({ activityId, studentId, versionId, data }) => axios.put(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}}/students/${studentId}/versions/${versionId}`,
    data,
);

/**
 * Загрузить файлы домашнего задания от преподавателя
 * @param {object} options
 * @param {number} options.modelId
 * @param {string} options.modelType
 * @param {File} options.file
 * @param {import('axios').AxiosRequestConfig} [params]
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const createFile = ({ modelId, modelType, file }, params) => {
    const formData = new FormData();

    formData.append('model_id', modelId);
    formData.append('model_type', modelType);
    formData.append('file', file, file.name);

    return axios.post(BASE_URI_FILES, formData, params);
};

/**
 * Отправить сообщение
 * @param activityId
 * @param studentId
 * @param data
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const sendMessage = ({ activityId, studentId, data }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/messages`, data);

/**
 * Редактировать сообщение
 * @param activityId
 * @param studentId
 * @param messageId
 * @param message
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const editMessage = ({ activityId, studentId, messageId, message }) => axios.put(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/messages/${messageId}`, { message });

/**
 * Удалить сообщение
 * @param activityId
 * @param studentId
 * @param messageId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const deleteMessage = ({ activityId, studentId, messageId }) => axios.delete(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/messages/${messageId}`);
