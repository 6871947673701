import axios from 'axios';
import { Utils } from '@sbs/uikit-constructor';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

const prefix = BASE_URI_DEVELOPMENT_CENTER;

/**
 * add question to activity
 * @param {number|string} activityId activity id
 * @param {object} questionData question data
 * @param {string} questionData.title question title
 * @param {string} questionData.description question description
 * @param {'OnlyChoice'|'MultipleChoice'|'GapsInTheText'|'Sorting'|'Matching'|'LikertScale'|'OpenQuestion'} questionData.type question type
 * @param {number} questionData.order question order for sort
 * @param {json} questionData.question_data object of question options in json
 * @param {Array<*>} questionData.answers
 * @returns Promise
 */
export const addPageActivityQuestion = (activityId, questionData) => {
    const url = `${prefix}/activities/${activityId}/questions`;

    return axios.post(url, questionData);
};

/**
 * copy activity question
 * @param {number|string} activityId activity id
 * @param {number|string} questionId question id
 * @returns Promise
 */
export const copyPageActivityQuestion = (activityId, questionId) => {
    const url = `${prefix}/activities/${activityId}/questions/${questionId}/copy`;

    return axios.post(url);
};

/**
 * change question of activity
 * @param {number|string} activityId activity id
 * @param {string|number} questionId question id
 * @param {object} questionData question data
 * @param {string} questionData.title question title
 * @param {string} questionData.description question description
 * @param {'OnlyChoice'|'MultipleChoice'|'GapsInTheText'|'Sorting'|'Matching'|'LikertScale'|'OpenQuestion'} questionData.type question type
 * @param {number} questionData.order question order for sort
 * @param {json} questionData.question_data object of question options in json
 * @returns Promise
 */
export const changePageActivityQuestion = (activityId, questionId, questionData, config) => {
    const url = `${prefix}/activities/${activityId}/questions/${questionId}`;

    return axios.put(url, questionData, config);
};

/**
 * remove activity question
 * @param {number|string} activityId activity id
 * @param {number|string} questionId question id
 * @returns Promise
 */
export const removePageActivityQuestion = (activityId, questionId) => {
    const url = `${prefix}/activities/${activityId}/questions/${questionId}`;

    return axios.delete(url);
};

/**
 * restore question of activity
 * @param {number|string} activityId activity id
 * @param {string|number} questionId question id
 * @returns Promise
 */
export const restorePageActivityQuestion = (activityId, questionId) => {
    const url = `${prefix}/activities/${activityId}/questions/${questionId}/restore`;

    return axios.post(url);
};

/**
 * sort question of activity
 * @param {number|string} activityId activity id
 * @param {string[]|number[]} sortArray array of question id's
 * @returns Promise
 */
export const sortPageActivityQuestion = (activityId, sortArray) => {
    const url = `${prefix}/activities/${activityId}/questions/sort`;

    return axios.post(url, { order: sortArray });
};

/**
 * add answer for activity question
 * @param {number|string} questionId question id
 * @param {object} answerData answer data
 * @param {string} answerData.title answer title
 * @param {1|0} answerData.is_correct is answer correct
 * @param {number} answerData.order answer order
 * @param {string} answerData.data answer text for question with other or free answer
 * @param {string} answerData.reactions_data answer reaction text
 * @returns Promise
 */
export const addPageActivityQuestionAnswer = (questionId, answerData) => {
    const url = `${prefix}/questions/${questionId}/answers`;

    return axios.post(url, answerData);
};

/**
 * change answer of activity question
 * @param {number|string} questionId question id
 * @param {number|string} answerId answer id
 * @param {object} answerData answer data
 * @param {string} answerData.title answer title
 * @param {1|0} answerData.is_correct is answer correct
 * @param {number} answerData.order answer order
 * @param {string} answerData.data answer text for question with other or free answer
 * @param {string} answerData.reactions_data answer reaction text
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns Promise
 */
export const changePageActivityQuestionAnswer = (questionId, answerId, answerData, config) => {
    const url = `${prefix}/questions/${questionId}/answers/${answerId}`;

    return axios.put(url, answerData, config);
};

/**
 * remove answer of activity question
 * @param {number|string} questionId question id
 * @param {number|string} answerId answer id
 * @returns Promise
 */
export const removePageActivityQuestionAnswer = (questionId, answerId) => {
    const url = `${prefix}/questions/${questionId}/answers/${answerId}`;

    return axios.delete(url);
};

/**
 * restore answer of activity question
 * @param {number|string} questionId question id
 * @param {number|string} answerId answer id
 * @returns Promise
 */
export const restorePageActivityQuestionAnswer = (questionId, answerId) => {
    const url = `${prefix}/questions/${questionId}/answers/${answerId}/restore`;

    return axios.post(url);
};

/**
 * sort answer of activity question
 * @param {number|string} questionId question id
 * @param {(number|string)[]} sortArray ordered array of answer ids
 * @returns Promise
 */
export const sortPageActivityQuestionAnswer = (questionId, sortArray) => {
    const url = `${prefix}/questions/${questionId}/answers/sort`;

    return axios.post(url, { order: sortArray });
};

/**
 * get test or quiz xls import file example
 * @param {number|string} activityId activity id
 * @returns Promise
 */
export const getPageActivityQuestionImportExample = activityId => {
    const url = `${prefix}/activities/${activityId}/questions/import_example`;

    return axios.get(url, { responseType: 'blob' });
};

/**
 * import activity questions from xlsx file
 * @param {number|string} activityId activity id
 * @param {File} file import xls file
 * @param {Function} [callback]
 * @returns Promise
 */
export const importPageActivityQuestions = (activityId, file, callback) => {
    const url = `${prefix}/activities/${activityId}/questions/import`;
    const formData = new FormData();

    formData.append('file', file, file.name);

    return axios.post(url, formData, {
        onUploadProgress: progressEvent => {
            if (Utils.Helpers.toRawType(callback) === 'Function') {
                callback(progressEvent);
            }
        },
    });
};

/**
 * export activity questions in xlsx file
 * @param {number|string} activityId activity id
 * @returns Promise
 */
export const exportPageActivityQuestions = activityId => {
    const url = `${prefix}/activities/${activityId}/questions/export`;

    return axios.get(url, { responseType: 'blob' });
};

/**
 * start export activity questions in xlsx file with answers
 * @param {number|string} activityId activity id
 * @returns Promise
 */
export const exportPageActivityQuestionsWithAnswers = activityId => axios.get(`${prefix}/activities/${activityId}/questions/export-user-answers`);

/**
 * delete all answers for activity question
 * @param {number|string} questionId question id
 * @returns Promise
 */
export const removePageActivityQuestionAnswers = questionId => axios.delete(`${prefix}/questions/${questionId}/answers/delete-all`);
