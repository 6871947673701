/* eslint-disable camelcase */

import { keyBy } from 'lodash';
import debounce from 'lodash/debounce.js';
import {
    getCoursesStudent,
    getCoursesStructure,
    getCoursesStudentProgress,
    changeStudentMaterialProgress, getCoursesStudents, importCourseStudents, createCourseStudentsReport,
    getCourseStudentsGroups,
    updateCourseStudentsGroups,
    removeCourseStudentsGroups,
    importCourseStudentsGroups,
} from '@/api/studentApi.js';
// eslint-disable-next-line import/no-cycle
import router from '@/router/index.js';
import { replaceItemInArrayById } from '@/utils/utils.js';
import { createCancelHttp, isHttpCancel } from '@/utils/http.js';
import useSnackbar from '@/hooks/snackbars.js';
import { validateFile } from '@/utils/validators.js';
import { bytes, getErrorMsg } from '@/utils/helpers.js';
import { getEchoInstance } from '@/services/echo.js';

const { createSnackbar } = useSnackbar();

/**
 * @typedef {'in_progress'|'passed'|'not_started'|'not_passed'|'on_checking'} MaterialStatus
 *
 * @typedef {'course'|'module'|'section'|'page'|'block'} MaterialType
 */

export const StudentsLoadingKeys = {
    STUDENT: 'student',
    STUDENT_PROGRESS: 'studentProgress',
    STUDENT_LIST: 'studentList',
    COURSE_STRUCTURE: 'courseStructure',
    COURSE_STRUCTURE_SEARCH: 'courseStructureSearch',
    STUDENT_MATERIAL_STATUS_CHANGE: 'changeStudentMaterialProgress',
    STUDENTS_GROUPS_LIST: 'studentsGroupsList',
};

const CHANGE_STUDENT_MATERIAL_STATUS = '.student.material.progress-v2';
const CHANGE_STUDENT_COURSE_STATUS = '.student.progress';

/**
 * @returns {CancelToken}
 */
const createCancelCourseStructure = () => createCancelHttp('getCurrentCoursesStructure').token;
/**
 * @returns {CancelToken}
 */
const createCancelStudentProgress = () => createCancelHttp('getStudentProgress').token;

const getKeyStudent = (courseId, studentId) => `${courseId}_${studentId}`;

const openedModulesSavedKey = (courseId, studentId) => `_student_opened_modules_${getKeyStudent(courseId, studentId)}`;
const openedSectionsSavedKey = (courseId, studentId) => `_student_opened_section_${getKeyStudent(courseId, studentId)}`;

/**
 * @param status
 * @param progress
 * @returns {function(*): boolean}
 */
const isBlockValid = ({ status, progress }) => block => progress.blocks[block.id].progress_status === status;

/**
 * @param modules
 * @param progress
 * @param status
 * @returns {*}
 */
const filterModules = (modules, { progress, status }) => {
    const isPageValid = page => progress.pages[page.id].progress_status === status || page.blocks.length > 0;
    const isSectionValid = section => progress.sections[section.id].progress_status === status || section.pages.length > 0;
    const isModuleValid = module => progress.modules[module.id].progress_status === status || module.pages.length > 0 || module.sections.length > 0;

    /**
     * Модифицируем и фильтруем страницы
     * @param pages
     * @returns {*}
     */
    const filterPages = pages => pages.reduce((arrPages, page) => {
        const newPage = {
            ...page,
            blocks: page.blocks.filter(isBlockValid({
                status,
                progress,
            })),
        };

        if (isPageValid(newPage)) {
            arrPages.push(newPage);
        }

        return arrPages;
    }, []);

    /**
     * Модифицируем и фильтруем разделы
     * @param sections
     * @returns {*}
     */
    const filterSections = sections => sections.reduce((arrSections, section) => {
        const newSection = {
            ...section,
            pages: filterPages(section.pages),
        };

        if (isSectionValid(newSection)) {
            arrSections.push(newSection);
        }

        return arrSections;
    }, []);

    return modules.reduce((arrModules, module) => {
        const newModule = {
            ...module,
            sections: filterSections(module.sections),
            pages: filterPages(module.pages),
        };

        if (isModuleValid(newModule)) {
            arrModules.push(newModule);
        }

        return arrModules;
    }, []);
};

/** @type {import('vuex').Module<any, any>} */
const storeStudent = {
    namespaced: true,
    state: {
        studentFilter: {},
        currentStudentId: null,
        currentCourseId: null,
        loading: Object.fromEntries(Object.values(StudentsLoadingKeys).map(keyName => [keyName, false])),
        students: {
            list: {},
            entities: {},
        },
        courses: {
            entities: {},
        },
        savedOpenedModules: [],
        savedOpenedSections: [],
        isShowAllModulesAndSections: false,
        studentsGroups: {
            list: {},
            entities: {},
        },
        selectedStudentsGroups: [],
    },
    getters: {
        studentFilter: state => state.studentFilter,
        searchQuery: state => state.studentFilter?.query ?? '',
        loading: state => loadingKey => state.loading[loadingKey] ?? false,
        student: state => (courseId, studentId) => state.students.entities[getKeyStudent(courseId, studentId)],
        studentProgress: (state, getters) => (courseId, studentId) => {
            const student = getters.student(courseId, studentId);

            return student && student.progress && {
                ...student.progress,
                course: {
                    ...student.progress.course,
                    modules: keyBy(student.progress.course.modules, 'id'),
                    sections: keyBy(student.progress.course.sections, 'id'),
                    pages: keyBy(student.progress.course.pages, 'id'),
                    blocks: keyBy(student.progress.course.blocks, 'id'),
                },
            };
        },
        studentEntityProgress: (state, getters) => ({ courseId, studentId, entityId, entityName }) => getters.studentProgress(courseId, studentId)?.course?.[entityName]?.[entityId],
        courseStructure: (state, getters) => (courseId, studentId) => {
            if (!state.courses.entities[courseId]?.structure) return null;

            const progress = getters.studentProgress(courseId, studentId)?.course;
            const { structure } = state.courses.entities[courseId];
            const { status } = state.studentFilter;

            if (!progress || !status) return structure;

            return {
                ...structure,
                direct_page: structure.direct_page
                    ? {
                        ...structure.direct_page,
                        blocks: structure.direct_page.blocks.filter(isBlockValid({
                            status,
                            progress,
                        })),
                    }
                    : null,
                modules: filterModules(structure.modules, {
                    progress,
                    status,
                }),
            };
        },
        currentStudent: (state, getters) => getters.student(state.currentCourseId, state.currentStudentId),
        currentStudentProgress: (state, getters) => getters.studentProgress(state.currentCourseId, state.currentStudentId),
        currentCourseStructure: (state, getters) => getters.courseStructure(state.currentCourseId, state.currentStudentId),
        isLoadingStudent: (state, getters) => getters.loading(StudentsLoadingKeys.STUDENT),
        isLoadingStudentProgress: (state, getters) => getters.loading(StudentsLoadingKeys.STUDENT_PROGRESS),
        isLoadingCourseStructure: (state, getters) => getters.loading(StudentsLoadingKeys.COURSE_STRUCTURE),
        isLoadingStudentList: (state, getters) => getters.loading(StudentsLoadingKeys.STUDENT_LIST),
        isChangingMaterialStatus: (state, getters) => getters.loading(StudentsLoadingKeys.STUDENT_MATERIAL_STATUS_CHANGE),
        isSearchingCourseStructure: (state, getters) => getters.loading(StudentsLoadingKeys.COURSE_STRUCTURE_SEARCH),
        courseStudents: state => courseId => state.students.list[courseId],
        openedModules: (state, getters) => (state.isShowAllModulesAndSections
            ? getters.currentCourseStructure?.modules.map(module => module.id) ?? []
            : state.savedOpenedModules),
        openedSections: (state, getters) => (state.isShowAllModulesAndSections
            ? getters.currentCourseStructure?.modules.map(module => module.sections.map(sec => sec.id)).flat() ?? []
            : state.savedOpenedSections),
        courseStudentsGroups: state => courseId => state.studentsGroups.list[courseId],
        isLoadingStudentsGroupsList: (state, getters) => getters.loading(StudentsLoadingKeys.STUDENTS_GROUPS_LIST),
        selectedStudentsGroups: state => state.selectedStudentsGroups,
    },
    mutations: {
        changeOpenedModules(state, openedModules) {
            state.savedOpenedModules = openedModules;
            state.isShowAllModulesAndSections = false;
            localStorage.setItem(openedModulesSavedKey(state.currentCourseId, state.currentStudentId), JSON.stringify(openedModules));
        },
        changeOpenedSections(state, openedSections) {
            state.savedOpenedSections = openedSections;
            state.isShowAllModulesAndSections = false;
            localStorage.setItem(openedSectionsSavedKey(state.currentCourseId, state.currentStudentId), JSON.stringify(openedSections));
        },
        restoreOpenedModulesAndSections(state, payload) {
            const { courseId, studentId } = payload;

            try {
                const openedModules = JSON.parse(localStorage.getItem(openedModulesSavedKey(courseId, studentId))) ?? [];
                const openedSections = JSON.parse(localStorage.getItem(openedSectionsSavedKey(courseId, studentId))) ?? [];

                state.savedOpenedModules = openedModules;
                state.savedOpenedSections = openedSections;
            } catch (e) {
                console.error(e);
            }
        },
        /**
         * @param state
         * @param {object} payload
         * @param {string|number} payload.courseId
         * @param {string|number} payload.studentId
         */
        setCurrentsForCourseStudent: (state, payload) => {
            state.currentCourseId = payload.courseId;
            state.currentStudentId = payload.studentId;
        },
        startLoading(state, loadingKey) {
            state.loading[loadingKey] = true;
        },
        stopLoading(state, loadingKey) {
            state.loading[loadingKey] = false;
        },
        setStudent(state, payload) {
            const studKey = getKeyStudent(payload.courseId, payload.studentId);

            state.students.entities[studKey] = {
                ...state.students.entities[studKey],
                data: payload.data,
            };
        },
        changeStudentProgressStatus(state, payload) {
            const studKey = getKeyStudent(payload.courseId, payload.studentId);

            if (state.students.entities[studKey]?.data) {
                state.students.entities[studKey].data.progress_status = payload.status;
            }

            if (state.students.list[payload.courseId]?.list) {
                const studentIndex = state.students.list[payload.courseId].list.findIndex(item => item.id === payload.studentId);

                if (studentIndex > -1) {
                    state.students.list[payload.courseId].list[studentIndex].progress_status = payload.status;
                }
            }
        },
        changeStudentGroupData(state, payload) {
            const { courseId, studentId } = payload;

            const list = state.students.list[courseId]?.list;
            const studentIndex = list.findIndex(item => item.id === studentId);

            if (studentIndex > -1) {
                state.students.list[courseId].list[studentIndex] = payload.data;
            }
        },
        setStudentProgress(state, payload) {
            const studKey = getKeyStudent(payload.courseId, payload.studentId);

            state.students.entities[studKey] = {
                ...state.students.entities[studKey],
                progress: payload.data,
            };
        },
        setCourseStructure(state, payload) {
            state.courses.entities[payload.id] = {
                ...state.courses.entities[payload.id],
                structure: payload.data,
            };
        },
        setStudentFilterValues(state, payload) {
            state.studentFilter = payload;
        },
        /**
         * @param state
         * @param {object} payload
         * @param {string|number} payload.courseId
         * @param {string|number} payload.studentId
         * @param {string|number} payload.entityId
         * @param {MaterialType} payload.entityType
         * @param {MaterialStatus} payload.status
         * @param {object} [payload.actions]
         */
        changeStudentMaterialStatus(state, payload) {
            const studKey = getKeyStudent(payload.courseId, payload.studentId);

            if (!state.students.entities[studKey]?.progress) return;

            const replaceElementWithStatus = arrayEntities => {
                const item = arrayEntities.find(el => el.id === payload.entityId);

                if (!item) return arrayEntities;

                return replaceItemInArrayById({
                    ...item,
                    progress_status: payload.status,
                    actions: payload.actions ?? item.actions,
                }, arrayEntities);
            };

            const { course } = state.students.entities[studKey].progress;

            switch (payload.entityType) {
                case 'course':
                    course.progress_status = payload.status;
                    break;
                case 'module':
                    course.modules = replaceElementWithStatus(course.modules);
                    break;
                case 'section':
                    course.sections = replaceElementWithStatus(course.sections);
                    break;
                case 'page':
                    course.pages = replaceElementWithStatus(course.pages);
                    break;
                case 'block':
                    course.blocks = replaceElementWithStatus(course.blocks);
                    break;
                default:
                    break;
            }
        },

        /**
         * @param state
         * @param {object} payload
         * @param {string|number} payload.courseId
         * @param {Array} payload.list
         * @param {object} payload.meta
         */
        setCourseStudentList(state, payload) {
            const { courseId, list, meta } = payload;

            state.students.list = {
                ...state.students.list,
                [courseId]: {
                    list,
                    meta,
                },
            };
        },

        /**
         * @param state
         * @param {object} payload
         * @param {string|number} payload.courseId
         * @param {Array} payload.list
         */
        appendCourseStudentList(state, payload) {
            const { courseId, list, meta } = payload;

            state.students.list = {
                ...state.students.list,
                [courseId]: {
                    list: [
                        ...(state.students.list[courseId]?.list ?? []),
                        ...list,
                    ],
                    meta,
                },
            };
        },

        showAllModulesAndSections(state) {
            state.isShowAllModulesAndSections = true;
        },

        /**
         * @param state
         * @param {object} payload
         * @param {string|number} payload.courseId
         * @param {Array} payload.list
         * @param {object} payload.meta
         */
        setCourseStudentsGroupsList(state, payload) {
            const { courseId, list, meta } = payload;

            state.studentsGroups.list = {
                ...state.studentsGroups.list,
                [courseId]: {
                    list,
                    meta,
                },
            };
        },

        /**
         * @param state
         * @param {object} payload
         * @param {string|number} payload.courseId
         * @param {Array} payload.list
         */
        appendCourseStudentsGroupsList(state, payload) {
            const { courseId, list, meta } = payload;

            state.studentsGroups.list = {
                ...state.studentsGroups.list,
                [courseId]: {
                    list: [
                        ...(state.studentsGroups.list[courseId]?.list ?? []),
                        ...list,
                    ],
                    meta,
                },
            };
        },
        setStudentsGroup(state, payload) {
            const currentList = state.studentsGroups.list[payload.courseId].list;

            state.studentsGroups.list[payload.courseId].list = currentList.map(item => (payload.item.id === item.id ? payload.item : item));
        },
        setSelectedStudentsGroups(state, payload) {
            state.selectedStudentsGroups = payload;
        },
    },
    actions: {
        initListStudentProgressChannel({ commit }, payload) {
            const { courseId } = payload;

            getEchoInstance()
                .private(`courses.${courseId}.students.progress`)
                .stopListening(CHANGE_STUDENT_COURSE_STATUS)
                .listen(CHANGE_STUDENT_COURSE_STATUS, changeStudCurseData => {
                    commit('changeStudentProgressStatus', {
                        courseId,
                        studentId: changeStudCurseData.id,
                        status: changeStudCurseData.progress_status,
                    });
                });
        },
        initStudentProgressChannel({ dispatch }, payload) {
            const { courseId, studentId } = payload;

            getEchoInstance()
                .private(`courses.${courseId}.students.${studentId}.progress`)
                .stopListening(CHANGE_STUDENT_MATERIAL_STATUS)
                .listen(CHANGE_STUDENT_MATERIAL_STATUS, debounce(() => {
                    dispatch('getStudentProgress', {
                        courseId,
                        studentId,
                        cancelToken: createCancelStudentProgress(),
                    });
                }, 100));

            dispatch('initListStudentProgressChannel', { courseId });
        },
        closeListStudentProgressChannel(ctx, payload) {
            const { courseId } = payload;

            if (!courseId) return;

            getEchoInstance().leave(`courses.${courseId}.students.progress`);
        },
        closeStudentProgressChannel({ dispatch }, payload) {
            const { courseId, studentId } = payload;

            dispatch('closeListStudentProgressChannel', { courseId });

            if (!courseId || !studentId) return;

            getEchoInstance().leave(`courses.${courseId}.students.${studentId}.progress`);
        },
        /**
         * @param injectee
         * @param {object} payload
         * @param {string|number} payload.courseId
         * @param {string|number} payload.studentId
         */
        loadStudentPage: ({ commit, dispatch }, payload) => {
            commit('setCurrentsForCourseStudent', payload);
            dispatch('getCurrentCoursesStructure');
            dispatch('getCurrentStudent');
            dispatch('getCurrentStudentProgress');
        },

        /**
         * @param ctx
         * @param {object} payload
         * @param {string} payload.status
         * @param {string} payload.query
         */
        applyStudentFilter: ({ dispatch, commit, state }, payload = {}) => {
            const oldFilter = state.studentFilter;

            commit('setStudentFilterValues', payload);

            if (oldFilter.query !== payload.query) {
                commit('startLoading', StudentsLoadingKeys.COURSE_STRUCTURE_SEARCH);
                dispatch('debouncedGetCurrentCoursesStructure', {
                    cancelToken: createCancelCourseStructure(),
                });
            }

            if (payload.status || payload.query) {
                commit('showAllModulesAndSections');
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @returns {Promise<void>}
         */
        async getCurrentStudent({ commit, state, getters }) {
            try {
                if (!getters.currentStudent?.data) {
                    commit('startLoading', StudentsLoadingKeys.STUDENT);
                }

                const resp = await getCoursesStudent({
                    courseId: state.currentCourseId,
                    studentId: state.currentStudentId,
                });

                commit('setStudent', {
                    data: resp.data.data,
                    courseId: state.currentCourseId,
                    studentId: state.currentStudentId,
                });
            } catch (e) {
                console.error(e);
            } finally {
                commit('stopLoading', StudentsLoadingKeys.STUDENT);
            }
        },

        /**
         * @param injectee
         * @param {object} payload
         * @returns {Promise<void>}
         */
        getStudentProgress: async ({ commit, getters }, { courseId, studentId, cancelToken }) => {
            try {
                if (!getters.studentProgress(courseId, studentId)) {
                    commit('startLoading', StudentsLoadingKeys.STUDENT_PROGRESS);
                }

                const resp = await getCoursesStudentProgress({
                    courseId,
                    studentId,
                }, {
                    cancelToken: cancelToken ?? createCancelStudentProgress(),
                });

                commit('setStudentProgress', {
                    data: resp.data.data,
                    courseId,
                    studentId,
                });

                commit('stopLoading', StudentsLoadingKeys.STUDENT_PROGRESS);
            } catch (e) {
                if (isHttpCancel(e)) return;

                console.error(e);
            }
        },

        /**
         * @param injectee
         * @param {object} payload
         * @returns {Promise<void>}
         */
        getCurrentStudentProgress: ({ dispatch, state }) => {
            dispatch('getStudentProgress', {
                courseId: state.currentCourseId,
                studentId: state.currentStudentId,
            });
        },

        /**
         * @param injectee
         * @param {object} [payload]
         * @returns {Promise<void>}
         */
        getCurrentCoursesStructure: async ({ commit, state, getters }, payload) => {
            try {
                if (!getters.currentCourseStructure) {
                    commit('startLoading', StudentsLoadingKeys.COURSE_STRUCTURE);
                }

                const { query } = state.studentFilter;

                const resp = await getCoursesStructure({
                    courseId: state.currentCourseId,
                }, {
                    params: query ? { query } : {},
                    cancelToken: payload?.cancelToken ?? createCancelCourseStructure(),
                });

                commit('setCourseStructure', {
                    id: state.currentCourseId,
                    data: resp.data.data,
                });

                commit('stopLoading', StudentsLoadingKeys.COURSE_STRUCTURE);
                commit('stopLoading', StudentsLoadingKeys.COURSE_STRUCTURE_SEARCH);
            } catch (e) {
                if (!isHttpCancel(e)) {
                    console.error(e);
                    commit('stopLoading', StudentsLoadingKeys.COURSE_STRUCTURE);
                    commit('stopLoading', StudentsLoadingKeys.COURSE_STRUCTURE_SEARCH);
                }
            }
        },

        debouncedGetCurrentCoursesStructure: debounce(({ dispatch }, payload) => {
            dispatch('getCurrentCoursesStructure', payload);
        }, 300),

        /**
         * @param ctx
         * @param {object} payload
         * @param {string|number} payload.entityId
         * @param {MaterialType} payload.entityType
         * @param {MaterialStatus} payload.status
         * @param {string} [payload.successMsg]
         * @param {string} [payload.errorMsg]
         * @return {Promise<void>}
         */
        // eslint-disable-next-line max-lines-per-function
        changeCurrentStudentMaterialStatus: async ({ commit, state }, {
            entityId,
            entityType,
            status,
            successMsg = 'Статус успешно изменён!',
            errorMsg = 'Ошибка смены статуса. Попробуйте ещё раз',
        }) => {
            const studentId = state.currentStudentId;
            const courseId = state.currentCourseId;

            try {
                commit('startLoading', StudentsLoadingKeys.STUDENT_MATERIAL_STATUS_CHANGE);
                commit('changeStudentMaterialStatus', {
                    studentId,
                    courseId,
                    entityId,
                    entityType,
                    status,
                });
                await changeStudentMaterialProgress({
                    courseId,
                    studentId,
                    entity_type: entityType,
                    entity_id: entityId,
                    status,
                });
                createSnackbar({
                    type: 'success',
                    message: successMsg,
                    timeout: 1500,
                });
            } catch (e) {
                console.error('[error changeCurrentStudentMaterialStatus]', e);
                createSnackbar({
                    type: 'error',
                    message: errorMsg,
                    timeout: 1500,
                });
            } finally {
                commit('stopLoading', StudentsLoadingKeys.STUDENT_MATERIAL_STATUS_CHANGE);
            }
        },

        /**
         * @param ctx
         * @param {object} payload
         * @param {*} payload.blockId
         * @param {*} payload.status
         */
        changeCurrentStudentBlockStatus: ({ dispatch, getters }, { blockId, status }) => {
            if (getters.currentStudentProgress?.course?.blocks?.[blockId]?.progress_status === status) return;

            dispatch('changeCurrentStudentMaterialStatus', {
                entityId: blockId,
                entityType: 'block',
                status,
            });
        },

        /**
         * @param commit
         * @param {object} payload
         * @param {string|number} payload.courseId
         * @param {object} [payload.params]
         * @param {boolean} [payload.withAppend]
         * @param {import('axios').CancelToken} [payload.cancelToken]
         * @return {Promise<void>}
         */
        fetchCourseStudents: async ({ commit }, payload) => {
            try {
                commit('startLoading', StudentsLoadingKeys.STUDENT_LIST);

                const { status, page, query, auth_waiting, only_with_groups } = payload.params ?? {};

                const resp = await getCoursesStudents({ courseId: payload.courseId }, {
                    params: {
                        status,
                        query,
                        page,
                        only_with_groups,
                        only_not_authorized: auth_waiting ? Number(auth_waiting) : null,
                    },
                    cancelToken: payload.cancelToken || createCancelHttp(`fetchCourseStudents${payload.courseId}`).token,
                });

                const courseStudentsData = {
                    courseId: payload.courseId,
                    list: resp.data.data,
                    meta: resp.data.meta,
                };

                if (payload.withAppend) {
                    commit('appendCourseStudentList', courseStudentsData);
                } else {
                    commit('setCourseStudentList', courseStudentsData);
                }

                commit('stopLoading', StudentsLoadingKeys.STUDENT_LIST);
            } catch (e) {
                if (isHttpCancel(e)) return;

                console.error(e);
                commit('stopLoading', StudentsLoadingKeys.STUDENT_LIST);
            }
        },
        /**
         * @param ctx
         * @param {object} payload
         * @param {string|number} payload.courseId
         * @param {string|number} payload.studentId
         * @param {object} payload.group
         */
        async updateCourseStudentGroup({ commit }, payload) {
            try {
                const { courseId, studentId, group } = payload;

                const { id, ...updatedItem } = {
                    ...group,
                    students: group.students?.map?.(student => student.id),
                };

                await updateCourseStudentsGroups({
                    courseId,
                    studentId: id,
                    ...updatedItem,
                });

                const { data } = await getCoursesStudent({
                    courseId,
                    studentId,
                });

                commit('changeStudentGroupData', {
                    studentId,
                    courseId,
                    data: data.data,
                });

                createSnackbar({
                    type: 'success',
                    message: 'Изменения сохранены',
                });
            } catch {
                createSnackbar({
                    type: 'error',
                    message: 'Ошибка. Попробуйте еще раз',
                });
            }
        },
        /**
         * @param ctx
         * @param payload
         * @param {string|number} payload.courseId
         * @param {File} payload.file
         * @return {Promise<void>}
         */
        importCourseStudentFromFile: async (ctx, payload) => {
            try {
                await validateFile(payload.file, {
                    maxSize: bytes('10MB'),
                    types: [
                        'application/vnd.ms-excel',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    ],
                });
                await importCourseStudents(payload);
                createSnackbar({
                    type: 'success',
                    message: 'Импорт добавлен в очередь',
                    timeout: 1500,
                });
            } catch (e) {
                console.error(e);
                createSnackbar({
                    type: 'error',
                    message: getErrorMsg(e),
                    timeout: 1500,
                });
            }
        },

        /**
         * @param ctx
         * @param {object} payload
         * @param {string|number} payload.courseId
         * @param {string|number} payload.developmentCenterId
         */
        makeCourseStudentsReport: async (ctx, payload) => {
            try {
                const resp = await createCourseStudentsReport({ courseId: payload.courseId });

                if (resp.data.success === false) {
                    throw resp.data;
                }

                createSnackbar({
                    type: 'info',
                    timeout: 2500,
                    size: 'large',
                    title: 'Отчет добавлен в очередь на обработку',
                    message: 'По завершению обработки, результаты по практике можно скачать на странице отчетов',
                    link: {
                        text: 'Перейти к отчетам',
                        route: {
                            name: 'AdminReports',
                            params: {
                                developmentCenterId: payload.developmentCenterId,
                            },
                        },
                    },
                });
            } catch (e) {
                console.error(e);
                createSnackbar({
                    type: 'error',
                    size: 'large',
                    title: getErrorMsg(e, 'Ошибка импорта. Попробуйте еще раз'),
                });
            }
        },

        /**
         * @param ctx
         * @param payload
         */
        // eslint-disable-next-line max-lines-per-function
        initImportChannel: ({ dispatch }, { userId }) => {
            getEchoInstance()
                .private(`users.${userId}.student`)
                // Студенты
                .stopListening('.student.imported')
                .listen('.student.imported', importData => {
                    const { imported_rows_count, total_rows_count, location } = importData;
                    const failedCount = total_rows_count - imported_rows_count;

                    createSnackbar({
                        type: 'info',
                        size: 'large',
                        timeout: 2500,
                        title: `Успешно импортировано ${imported_rows_count} из ${total_rows_count}`,
                        message: failedCount
                            ? `По ${failedCount} учащимся не удалось получить данные. Проверьте правильность заполнения или дождитесь пока система получит данные об учениках после их перехода в новый интерфейс курсов`
                            : '',
                        link: {
                            text: 'Перейти в список учащихся',
                            route: {
                                name: 'VersionStudentsMainView',
                                params: {
                                    developmentCenterId: location.development_center_id,
                                    solutionId: location.project_id,
                                    versionId: location.course_id,
                                },
                            },
                        },
                    });

                    if (router.currentRoute.value.name === 'VersionStudentsMainView' && location.course_id.toString() === router.currentRoute.value.params.versionId) {
                        router.replace({
                            query: {},
                        });
                        dispatch('fetchCourseStudents', {
                            courseId: location.course_id,
                            params: router.currentRoute.value.query,
                        });
                    }
                })
                .stopListening('.student.import_failed')
                .listen('.student.import_failed', importData => {
                    const { location } = importData;

                    createSnackbar({
                        type: 'error',
                        size: 'large',
                        title: 'Ошибка импорта. Попробуйте еще раз',
                        link: {
                            text: 'Перейти в список учащихся',
                            route: {
                                name: 'VersionStudentsMainView',
                                params: {
                                    developmentCenterId: location.development_center_id,
                                    solutionId: location.project_id,
                                    versionId: location.course_id,
                                },
                            },
                        },
                    });
                })
                // Группы
                .stopListening('.group.imported')
                .listen('.group.imported', importData => {
                    const { imported_rows_count, total_rows_count, location } = importData;
                    const failedCount = total_rows_count - imported_rows_count;

                    createSnackbar({
                        type: 'info',
                        size: 'large',
                        timeout: 2500,
                        title: `Успешно импортировано ${imported_rows_count} из ${total_rows_count} групп`,
                        message: failedCount
                            ? `По ${failedCount} строкам не заполнены логины или все ячейки с названиями групп для одного логина остались незаполненными. Проверьте правильность заполнения`
                            : '',
                        link: {
                            text: 'Перейти к блоку',
                            route: {
                                name: 'VersionStudentsMainView',
                                params: {
                                    developmentCenterId: location.development_center_id,
                                    solutionId: location.project_id,
                                    versionId: location.course_id,
                                },
                            },
                        },
                    });

                    if (router.currentRoute.value.name === 'VersionStudentsMainView' && location.course_id.toString() === router.currentRoute.value.params.versionId) {
                        router.replace({
                            query: {},
                        });
                        dispatch('fetchCourseStudentsGroups', {
                            courseId: location.course_id,
                            params: router.currentRoute.value.query,
                        });
                    }
                })
                .stopListening('.group.import_failed')
                .listen('.group.import_failed', importData => {
                    const { location } = importData;

                    createSnackbar({
                        type: 'error',
                        size: 'large',
                        title: 'Ошибка импорта. Попробуйте еще раз',
                        link: {
                            text: 'Перейти в список групп',
                            route: {
                                name: 'VersionStudentsMainView',
                                params: {
                                    developmentCenterId: location.development_center_id,
                                    solutionId: location.project_id,
                                    versionId: location.course_id,
                                },
                            },
                        },
                    });
                });
        },
        fetchCourseStudentsGroups: async ({ commit }, payload) => {
            try {
                commit('startLoading', StudentsLoadingKeys.STUDENTS_GROUPS_LIST);

                const { page, query, is_empty, is_hidden } = payload.params ?? {};

                const { data } = await getCourseStudentsGroups({ courseId: payload.courseId }, {
                    params: {
                        page,
                        query,
                        is_empty,
                        is_visible: Number(!is_hidden),
                    },
                });

                const courseStudentsData = {
                    courseId: payload.courseId,
                    list: data.data,
                    meta: data.meta,
                };

                if (payload.withAppend) {
                    commit('appendCourseStudentsGroupsList', courseStudentsData);
                } else {
                    commit('setCourseStudentsGroupsList', courseStudentsData);
                }
            } finally {
                commit('stopLoading', StudentsLoadingKeys.STUDENTS_GROUPS_LIST);
            }
        },
        async editCourseStudentsGroup({ commit }, { courseId, item }) {
            try {
                const { id, ...updatedItem } = {
                    ...item,
                    students: item.students?.map?.(student => student.id),
                };

                const { data } = await updateCourseStudentsGroups({
                    courseId,
                    studentId: id,
                    ...updatedItem,
                });

                commit('setStudentsGroup', {
                    courseId,
                    item: data.data,
                });

                createSnackbar({
                    type: 'success',
                    message: 'Изменения сохранены',
                });
            } catch {
                createSnackbar({
                    type: 'error',
                    message: 'Ошибка. Попробуйте еще раз',
                });
            }
        },
        async removeCourseStudentsGroup({ dispatch }, { courseId, items }) {
            try {
                await removeCourseStudentsGroups({
                    courseId,
                    groups: items,
                });

                dispatch('fetchCourseStudentsGroups', { courseId });

                createSnackbar({
                    type: 'success',
                    message: items.length === 1 ? 'Группа удалена' : 'Группы удалены',
                });
            } catch {
                createSnackbar({
                    type: 'error',
                    message: 'Ошибка. Попробуйте еще раз',
                });
            }
        },
        updateSelectedGroups({ commit }, groupsIds) {
            commit('setSelectedStudentsGroups', groupsIds);
        },
        // todo: одинаковый код в importCourseStudentFromFile, отрефакторить на переиспользуемыый
        /**
         * @param ctx
         * @param payload
         * @param {string|number} payload.courseId
         * @param {File} payload.file
         * @return {Promise<void>}
         */
        importCourseStudentsGroupsFromFile: async (ctx, payload) => {
            try {
                await validateFile(payload.file, {
                    maxSize: bytes('10MB'),
                    types: [
                        'application/vnd.ms-excel',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    ],
                });

                await importCourseStudentsGroups(payload);

                createSnackbar({
                    type: 'success',
                    message: 'Импорт добавлен в очередь',
                    timeout: 1500,
                });
            } catch (e) {
                console.error(e);
                createSnackbar({
                    type: 'error',
                    message: getErrorMsg(e),
                    timeout: 1500,
                });
            }
        },
    },
};

export default storeStudent;
