import { groupBy } from 'lodash';
import {
    addCourseTerm,
    clearCourseTerms,
    deleteCourseTermById,
    restoreCourseTermById,
    getCourseTermsList,
    restoreCourseTerms,
    updateCourseTermById,
} from '@/api/termsApi.js';
import useSnackbar from '@/hooks/snackbars.js';
import undoIcon from '@/assets/svg/icons-backward-arrow.svg';

const { createSnackbar } = useSnackbar();

export default {
    namespaced: true,
    state: {
        data: null,
        loading: true,
    },
    getters: {
        getGlossaryList: state => Object.entries(groupBy(state.data?.data ?? [], 'sign')).map(([key, items]) => ({
            id: key === 'symbol' ? '...' : key,
            items,
        })),
        getGlossaryData: state => state.data,
        getGlossaryListLoading: state => state.loading,
    },
    mutations: {
        setGlossaryList: (state, payload) => {
            state.data = payload;
        },
        appendGlossaryList: (state, payload) => {
            state.data = {
                ...payload,
                data: [...state.data.data, ...payload.data],
            };
        },
        setGlossaryListLoading: (state, payload) => {
            state.loading = payload;
        },
    },
    actions: {
        async fetchGlossaryList({ commit }, { versionId, append, params } = {}) {
            if (!versionId) return;

            commit('setGlossaryListLoading', true);

            try {
                const { data } = await getCourseTermsList({
                    courseId: versionId,
                    params,
                });

                if (append) {
                    commit('appendGlossaryList', data);
                } else {
                    commit('setGlossaryList', data);
                }
            } catch (e) {
                console.error(e);
                createSnackbar({
                    type: 'error',
                    message: 'Ошибка загрузки. Попробуйте еще раз',
                });
            } finally {
                commit('setGlossaryListLoading', false);
            }
        },
        async addGlossaryTerm({ dispatch }, { versionId, data } = {}) {
            if (!versionId) return;

            try {
                await addCourseTerm({
                    courseId: versionId,
                    data,
                });

                dispatch('fetchGlossaryList', { versionId });
            } catch (e) {
                console.error(e);
                createSnackbar({
                    type: 'error',
                    message: 'Ошибка загрузки. Попробуйте еще раз',
                });
            }
        },
        async editGlossaryTerm({ dispatch }, { versionId, data } = {}) {
            if (!versionId) return;

            try {
                await updateCourseTermById({
                    courseId: versionId,
                    data,
                });

                dispatch('fetchGlossaryList', { versionId });
            } catch (e) {
                console.error(e);
                createSnackbar({
                    type: 'error',
                    message: 'Ошибка загрузки. Попробуйте еще раз',
                });
            }
        },
        async removeGlossaryTerm({ dispatch }, { versionId, termId } = {}) {
            if (!versionId || !termId) return;

            try {
                await deleteCourseTermById({
                    courseId: versionId,
                    termId,
                });

                dispatch('fetchGlossaryList', { versionId });
            } catch (e) {
                console.error(e);
                createSnackbar({
                    type: 'error',
                    message: 'Ошибка загрузки. Попробуйте еще раз',
                });
                throw e;
            }
        },
        async restoreGlossaryTerm(_, { versionId, termId } = {}) {
            if (!versionId || !termId) return;

            try {
                await restoreCourseTermById({
                    courseId: versionId,
                    termId,
                });

            } catch (e) {
                console.error(e);
                createSnackbar({
                    type: 'error',
                    message: 'Ошибка загрузки. Попробуйте еще раз',
                });
                throw e;
            }
        },
        async restoreGlossary({ dispatch }, { versionId, restoreToken } = {}) {
            try {
                await restoreCourseTerms({
                    courseId: versionId,
                    restoreToken,
                });

                dispatch('fetchGlossaryList', { versionId });
            } catch (e) {
                console.error(e);
                createSnackbar({
                    type: 'error',
                    message: 'Ошибка. Попробуйте еще раз',
                });
            }
        },
        async clearGlossary({ dispatch }, { versionId } = {}) {
            if (!versionId) return;

            try {
                const { data } = await clearCourseTerms({ courseId: versionId });

                dispatch('fetchGlossaryList', { versionId });

                createSnackbar({
                    type: 'timer',
                    message: 'Глоссарий очищен',
                    actionButton: {
                        iconInline: undoIcon,
                        text: 'Отменить',
                        on: {
                            click: () => {
                                dispatch('restoreGlossary', {
                                    versionId,
                                    restoreToken: data.data.restore_token,
                                });
                            },
                        },
                    },
                    timeout: 5000,
                });
            } catch (e) {
                console.error(e);
                createSnackbar({
                    type: 'error',
                    message: 'Ошибка. Попробуйте еще раз',
                });
            }
        },
    },
};
