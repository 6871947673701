import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';


/**
 * get participation students
 * @param {number|string} activityId activity id
 * @param {object} params
 * @returns Promise
 */

/**
 *
 * @param {object} options
 * @param {*} options.eventId
 * @param {*} options.page
 * @param {*} options.search
 * @param {*} options.is_not_authorized
 * @param {*} options.status
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<AxiosResponse<any>>}
 */
export const participationStudents = (options, config = {}) => {
    /* eslint-disable camelcase */
    const { eventId, page, search, is_not_authorized, status } = options;

    return axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/activities/${eventId}/participation`, {
        ...config,
        params: {
            page,
            search,
            is_not_authorized: is_not_authorized ? 1 : 0,
            statuses: status,
        },
    });
    /* eslint-enable camelcase */
};

/**
 * update participation students
 * @param {number|string} activityId activity id
 * @returns Promise
 */


export const updateParticipationStudents = (activityId, data) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/activities/${activityId}/participation`, data);


/**
 * get all events
 * @param {number|string} versionId version id
 * @param {number|string} page
 * @param {'online' | 'offline'} type
 * @param {'newest' | 'oldest'} sort
 * @returns {Promise<AxiosResponse>} Promise
 */

export const getAllEvents = ({ versionId, page, type, sort }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/versions/${versionId}/events`, {
    params: {
        page,
        event_type: type,
        sort,
    },
});

/**
 * get event
 * @param {number|string} activityId activity id
 * @returns Promise
 */


export const getEvent = activityId => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/activities/${activityId}`);

/**
 * Получить файл шаблона для импорта учеников в систему.
 * @param {number|string} versionId version id
 * @returns {string}
 */
export const getImportingParticipantsTemplate = ({ versionId }) => `${BASE_URI_DEVELOPMENT_CENTER}/event-participation/courses/${versionId}/events-participation/import/template`;

/**
 * Импорт файла решений ДЗ.
 * @param {number|string} activityId version id
 * @param {Blob} file Файл
 * @returns {Promise<AxiosResponse<any>>}
 */
export const importFileParticipants = ({ versionId, file }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/event-participation/courses/${versionId}/events-participation/import`, file);

/**
 * Импорт файла решений ДЗ.
 * @param {number|string} versionId version id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getParticipantsReport = ({ versionId }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/event-participation/courses/${versionId}/events-participation/export`);
