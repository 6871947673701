import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

/**
 * @typedef {object} TermData
 * @property {string} term
 * @property {string} description
 */

/**
 * @typedef {object} Term
 * @property id
 * @property course_id
 * @property term
 * @property description
 * @property sign
 */

/**
 * Получение списка терминов глоссария
 *
 * @typedef {object} TermListParams
 * @property {string} [search]
 * @property {Array} [signs]
 * @property {string|number} [page]
 *
 * @param {object} data
 * @param {string|number} data.courseId
 * @param {TermListParams} data.params
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<import('axios').AxiosResponse<{ data: Term[], links: object, meta: object }>>}
 */
export const getCourseTermsList = ({ courseId, params }, config) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/glossary/courses/${courseId}/terms`, {
    ...config,
    params,
});

/**
 * Добавление нового термина
 * @param {object} params
 * @param {string|number} params.courseId
 * @param {TermData} params.data
 * @returns {Promise<import('axios').AxiosResponse<{ data: Term }>>}
 */
export const addCourseTerm = ({ courseId, data }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/glossary/courses/${courseId}/terms`, data);

/**
 * Обновление термина
 * @param {object} param
 * @param {string|number} param.courseId
 * @param {string|number} param.termId
 * @param {object} param.data
 * @returns {Promise<import('axios').AxiosResponse<{ data: Term }>>}
 */
export const updateCourseTermById = ({ termId, courseId, data }) => axios.put(`${BASE_URI_DEVELOPMENT_CENTER}/glossary/courses/${courseId}/terms/${termId}`, data);

/**
 * получить термина
 * @param termId
 * @param courseId
 * @returns {Promise<import('axios').AxiosResponse<{ data: Term }>>}
 */
export const getTermById = ({ termId, courseId }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/glossary/courses/${courseId}/terms/${termId}`);

/**
 * Удаление термина
 * @param courseId
 * @param termId
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const deleteCourseTermById = ({ termId, courseId }) => axios.delete(`${BASE_URI_DEVELOPMENT_CENTER}/glossary/courses/${courseId}/terms/${termId}`);

/**
 * восстановить термина
 * @param courseId
 * @param termId
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const restoreCourseTermById = ({ termId, courseId }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/glossary/courses/${courseId}/terms/${termId}/restore`);

/**
 * Привязка термина к блоку
 * @param data
 * @param data.termId
 * @param data.courseId
 * @param data.blockId
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const attachTermToBlock = ({
    termId,
    courseId,
    blockId,
}) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/glossary/courses/${courseId}/terms/${termId}/attach`, {
    block_id: blockId,
});

/**
 * Отвязка термина от блока
 * @param data
 * @param data.termId
 * @param data.courseId
 * @param data.blockId
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const detachTermFromBlock = ({
    termId,
    courseId,
    blockId,
}) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/glossary/courses/${courseId}/terms/${termId}/detach`, {
    block_id: blockId,
});

/**
 * Проверка на наличие термина в курсе
 * @param data
 * @param data.term
 * @param data.courseId
 * @returns {Promise<import('axios').AxiosResponse<{ data: Term|null }>>}
 */
export const checkTermExisting = ({
    term,
    courseId,
}) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/glossary/courses/${courseId}/terms/exists`, {
    term,
});

/**
 * Список активных символов алфавита в курсе
 * @param data
 * @param data.courseId
 * @returns {Promise<import('axios').AxiosResponse<{ data: string[] }>>}
 */
export const getGlossaryActiveSigns = ({ courseId }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/glossary/courses/${courseId}/terms/signs`);

/**
 * Очистка терминов в курсе
 * @param data
 * @param data.courseId
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const clearCourseTerms = ({ courseId }) => axios.delete(`${BASE_URI_DEVELOPMENT_CENTER}/glossary/courses/${courseId}/terms/clear`);

/**
 * Восстановление терминов в курсе
 * @param data
 * @param data.courseId
 * @param data.restoreToken
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const restoreCourseTerms = ({ courseId, restoreToken }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/glossary/courses/${courseId}/terms/restore`, { restore_token: restoreToken });
