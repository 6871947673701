<template>
    <component
        :is="as"
        :class="classes">
        <slot></slot>
    </component>
</template>

<script>
export const TextBlockKinds = {
    /**
     * TODO Запрлнить остальное
     */
    // '': 'typo-h1-bold',
    // '': 'typo-h2-bold',
    'Desktop / H3 (Bold)': 'typo-h3-bold',
    // '': 'typo-h3-medium',
    // '': 'typo-h3-demi',
    // '': 'typo-h3-book',
    // '': 'typo-h4-bold',
    'Desktop / H4 (Demi)': 'typo-h4-demi',
    // '': 'typo-primary-bold',
    'Desktop / Основной (Medium)': 'typo-primary-medium',
    'Desktop / Основной (Demi)': 'typo-primary-demi',
    // '': 'typo-primary-book',
    // '': 'typo-subprimary-bold',
    'Desktop / Подпись (Medium)': 'typo-subprimary-medium',
    'Desktop / Подпись (Demi)': 'typo-subprimary-demi',
    // '': 'typo-subprimary-book',
    // '': 'typo-secondary-bold',
    'Desktop/Дополнительный (Medium)': 'typo-secondary-medium',
    'Desktop/Дополнительный (Demi)': 'typo-secondary-demi',
    'Desktop/Дополнительный (Book)': 'typo-secondary-book',
    // '': 'typo-additional-bold',
    'Desktop/Дополнительный №2 (Medium)': 'typo-additional-medium',
    'Desktop/Дополнительный №2 (Demi)': 'typo-additional-demi',
    'Desktop/Дополнительный №2 (Book)': 'typo-additional-book',
    'Desktop / Подпись №2 (Demi)': 'typo-subsecondary-demi',
    'Desktop/Подпись №2 (Demi)': 'typo-subsecondary-demi',
    'Desktop/Подпись №2 (Book)': 'typo-subsecondary-book',
    // '': 'typo-subtext-demi',
    'TODO': 'TODO',

    // new
    'Desktop/font/body/m1-medium': 'typo-primary-medium',
    'Desktop/font/body/s2-demi': 'typo-additional-demi',
    'Desktop/font/body/s2-book': 'typo-additional-book',
    'Desktop/font/body/s1-demi': 'typo-secondary-demi',
    'Desktop/font/body/s1-book': 'typo-secondary-book',
    'Desktop/font/caption/s-book': 'typo-subsecondary-book',
    'Desktop/font/caption/s-demi': 'typo-subsecondary-demi',
};

export default {
    name: 'TextBlock',
    computed: {
        classes() {
            return ['TextBlock', `TextBlock--Kind-${TextBlockKinds[this.kind] ?? this.kind}`];
        },
    },
    props: {
        as: {
            type: [Object, String],
            default: 'div',
        },
        kind: {
            type: String,
            default: 'TODO',
            validator: val => Object.values(TextBlockKinds).includes(val) || Object.keys(TextBlockKinds).includes(val),
        },
    },
};
</script>

<style lang="scss">
.TextBlock {
    font-family: inherit;
}
/* stylelint-disable */
.TextBlock--Kind-typo-h1-bold { @include typo-h1-bold(); }
.TextBlock--Kind-typo-h2-bold { @include typo-h2-bold(); }
.TextBlock--Kind-typo-h3-bold { @include typo-h3-bold(); }
.TextBlock--Kind-typo-h3-medium { @include typo-h3-medium(); }
.TextBlock--Kind-typo-h3-demi { @include typo-h3-demi(); }
.TextBlock--Kind-typo-h3-book { @include typo-h3-book(); }
.TextBlock--Kind-typo-h4-bold { @include typo-h4-bold(); }
.TextBlock--Kind-typo-h4-demi { @include typo-h4-demi(); }
.TextBlock--Kind-typo-primary-bold { @include typo-primary-bold(); }
.TextBlock--Kind-typo-primary-medium { @include typo-primary-medium(); }
.TextBlock--Kind-typo-primary-demi { @include typo-primary-demi(); }
.TextBlock--Kind-typo-primary-book { @include typo-primary-book(); }
.TextBlock--Kind-typo-subprimary-bold { @include typo-subprimary-bold(); }
.TextBlock--Kind-typo-subprimary-medium { @include typo-subprimary-medium(); }
.TextBlock--Kind-typo-subprimary-demi { @include typo-subprimary-demi(); }
.TextBlock--Kind-typo-subprimary-book { @include typo-subprimary-book(); }
.TextBlock--Kind-typo-secondary-bold { @include typo-secondary-bold(); }
.TextBlock--Kind-typo-secondary-medium { @include typo-secondary-medium(); }
.TextBlock--Kind-typo-secondary-demi { @include typo-secondary-demi(); }
.TextBlock--Kind-typo-secondary-book { @include typo-secondary-book(); }
.TextBlock--Kind-typo-additional-bold { @include typo-additional-bold(); }
.TextBlock--Kind-typo-additional-medium { @include typo-additional-medium(); }
.TextBlock--Kind-typo-additional-demi { @include typo-additional-demi(); }
.TextBlock--Kind-typo-additional-book { @include typo-additional-book(); }
.TextBlock--Kind-typo-subsecondary-demi { @include typo-subsecondary-demi(); }
.TextBlock--Kind-typo-subsecondary-book { @include typo-subsecondary-book(); }
.TextBlock--Kind-typo-subtext-demi { @include typo-subtext-demi(); }
</style>
