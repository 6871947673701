import { findLast } from 'lodash';
import orderBy from 'lodash/orderBy.js';
import axios from 'axios';
import isEqual from 'lodash/isEqual.js';
import {
    cancelAndCreatHomeworkResponse,
    createHomeworkAnswerByTeacher,
    createHomeworkResponse,
    createFile,
    fetchHomework as fetchHomeworkData,
    fetchHomeworkHistory,
    homeworkHistoryViewed,
    updateHomeworkResponse,
    startHomeworkCheckingSession,
    prolongHomeworkCheckingSession,
    getHomeworkCheckingSession,
    sendMessage,
    editMessage,
    deleteMessage,
    fetchHomeworkHistoryItem,
    updateHomeworkAnswerByTeacher,
    finishHomeworkCheckingSession,
} from '@/api/homeworkApi.js';
import { StatusesMap } from '@/admin/components/Homework/constants.js';
import { MAX_FILE_SIZE_IN_BYTES } from '@/admin/components/Homework/HomeworkAddingModal/constants.js';
import { HOMEWORK_HISTORY_ITEM_TYPES } from '@/constants/index.js';
import { ClientErrorCodes } from '@/utils/httpStatusCodes.js';
import { validateFile } from '@/utils/validators.js';
import { getFileData } from '@/utils/fileData.js';
import { throwIf } from '@/utils/utils.js';

const errorHandler = (error, actionName, errorName) => {
    const logingErrorName = `${actionName}: ${errorName}`;

    console.error(logingErrorName, error);

    throw new Error(errorName);
};

const HISTORY_SORT = 'desc';

const generateBaseRequestBody = (state, { versionId, responseId, data, homeworkId }) => ({
    homeworkId: homeworkId || state.homework.id,
    versionId,
    responseId,
    data: {
        comment: state.editableItem?.text || '',
        ...data,
    },
});

const DEFAULT_LOADERS = {
    list: false,
    next: false,
    checking: false,
    uploadingEditFiles: false,
    savingEditFiles: false,
};

const DEFAULT_MODALS = {
    confirmCancelEditFiles: false,
};

export default {
    namespaced: true,
    state: {
        homeworkId: null,
        studentId: null,
        activityId: null,
        homework: {},
        historyList: [],
        historyMetaData: {},
        nextCursor: null,
        currentCheckingSession: null,
        editableItem: null,
        editableVersionFiles: {
            item: null,
            files: [],
            addedFiles: [],
        },
        editableMessage: null,
        relatedItem: null,
        loaders: DEFAULT_LOADERS,
        modals: DEFAULT_MODALS,
    },
    getters: {
        homework: state => state.homework.homework ?? {},
        homeworkActions: state => state.homework.actions ?? {},
        homeworkId: state => state.homework?.homework?.id,
        activityId: state => state.activityId,
        historyList: state => state.historyList,
        performer: state => state.homework.performer_data,
        performerId: (state, getters) => getters.performer.id,
        student: (state, getters) => getters.performer,
        studentId: (state, getters) => getters.performerId,
        status: state => state.homework.homework?.status,
        historyMetaData: state => state.historyMetaData,
        editableItem: state => state.editableItem,
        isListLoading: state => state.loaders.list,
        isNextLoading: state => state.loaders.next,
        isCheckingLoading: state => state.loaders.checking,
        lastVersion: (state, getters) => findLast(
            getters.historyList,
            item => !item.deleted_at && [HOMEWORK_HISTORY_ITEM_TYPES.ESSAY_VERSION, HOMEWORK_HISTORY_ITEM_TYPES.FILES_VERSION].includes(item.type),
        ),
        lastResponse: (state, getters) => findLast(
            getters.historyList,
            item => !item.deleted_at && item.type === HOMEWORK_HISTORY_ITEM_TYPES.RESPONSE,
        ),
        lastVersionOrResponseItem: (_, getters) => {
            const { historyList, lastResponse, lastVersion } = getters;

            return findLast(historyList, item => item.id === lastResponse?.id || item.id === lastVersion?.id);
        },
        isAccepted: (state, getters) => (getters.homework?.status === StatusesMap.ACCEPTED || getters.homework?.status === StatusesMap.PASSED),
        isEmpty: (state, getters) => !getters.historyList.length,
        lastHistoryItemId: (state, getters) => target => getters.historyList.find(item => item.id === target.last_version_id)?.id,
        editableMessage: state => state.editableMessage,
        currentCheckingSession: state => state.currentCheckingSession,
        relatedItem: state => state.relatedItem,
        editableVersionFiles: state => [...state.editableVersionFiles.files, ...state.editableVersionFiles.addedFiles],
        shouldSaveVersionFiles: (state, getters) => getters.editableVersionFiles.length > 0 &&
            !isEqual(state.editableVersionFiles.item?.data.files, getters.editableVersionFiles),
    },
    mutations: {
        setNextCursor(state, value) {
            state.nextCursor = value;
        },
        setHistoryList(state, value) {
            state.historyList = orderBy(value, ['id'], ['asc']);
        },
        setHomework(state, value) {
            state.homework = value;
        },
        setHomeworkId(state, value) {
            state.homeworkId = value;
        },
        setStudentId(state, value) {
            state.studentId = value;
        },
        setActivityId(state, value) {
            state.activityId = value;
        },
        setHistoryMetaData(state, value) {
            state.historyMetaData = value;
        },
        setEditableItem(state, value) {
            state.editableItem = value;
        },
        setEditableVersionFiles(state, item) {
            state.editableVersionFiles = {
                item,
                files: item.data.files,
                addedFiles: [],
            };
        },
        clearEditableVersionFile(state) {
            state.editableVersionFiles = {
                item: null,
                files: [],
                addedFiles: [],
            };
        },
        /**
         * @param state
         * @param {object} data
         * @param {Array} data.files
         * @param {Array} data.addedFiles
         */
        changeEditableFiles(state, data) {
            state.editableVersionFiles = {
                ...state.editableVersionFiles,
                ...data,
            };
        },
        updateAddedEditableFileProgress(state, { file, progress }) {
            state.editableVersionFiles = {
                ...state.editableVersionFiles,
                addedFiles: state.editableVersionFiles.addedFiles.map(item => {
                    if (item.originalFile === file) {
                        return {
                            ...item,
                            progress,
                        };
                    }

                    return item;
                }),
            };
        },
        /**
         * @param state
         * @param {object} value
         * @param {keyof DEFAULT_LOADERS} value.name
         * @param {boolean} value.show
         */
        setLoaders(state, value) {
            state.loaders[value.name] = value.show;
        },
        /**
         * @param state
         * @param {object} value
         * @param {keyof DEFAULT_MODALS} value.name
         * @param {boolean} value.show
         */
        setModals(state, value) {
            state.modals[value.name] = value.show;
        },
        setEditableMessage(state, value) {
            state.editableMessage = value;
        },
        setCurrentSession(state, value) {
            state.currentCheckingSession = value;
        },
        setRelatedItem(state, value) {
            state.relatedItem = value;
        },
        resetHistory(state) {
            state.historyList = [];
        },
    },
    actions: {
        resetEditableItem({ commit }) {
            commit('setEditableItem', null);
        },
        updateEditableItem({ commit, getters }, params) {
            commit('setEditableItem', {
                ...getters.editableItem,
                ...params,
            });
        },
        /**
         * @param ctx
         * @param {object} params
         * @param {string} [params.cursor]
         * @returns {Promise<T>}
         */
        async fetchHistoryData({ state, getters }, params = {}) {
            const homeworkId = state.homeworkId || state.homework?.id;
            const { activityId, studentId } = getters;

            if (!homeworkId) return null;

            try {
                const response = await fetchHomeworkHistory({
                    activityId,
                    studentId,
                    cursor: params.cursor,
                    order: HISTORY_SORT,
                });

                return response.data;
            } catch {
                throw new Error('fetchHistoryData: Ошибка получения истории ДЗ');
            }
        },
        async updateViewedLastHistory({ getters }, id) {
            try {
                const { activityId, studentId } = getters;

                await homeworkHistoryViewed({
                    activityId,
                    studentId,
                    historyId: id,
                });

            } catch {
                throw new Error('fetchHomework: Ошибка обновления информации о просмотре ДЗ');
            }
        },
        async fetchHistoryNextPage({ state, dispatch, commit }) {
            const { nextCursor } = state;

            if (!nextCursor) return;

            const historyCursorPage = await dispatch('fetchHistoryData', { cursor: nextCursor });

            if (historyCursorPage) {
                commit('setNextCursor', historyCursorPage.meta.next_cursor);
                commit('setHistoryList', [
                    ...state.historyList,
                    ...historyCursorPage.data,
                ]);
            }
        },
        async fetchHistory({ dispatch, commit }) {
            const historyCursorPage = await dispatch('fetchHistoryData');

            if (historyCursorPage) {
                commit('setNextCursor', historyCursorPage.meta.next_cursor);
                commit('setHistoryList', historyCursorPage.data);

                const lastHistory = historyCursorPage.data[0];

                if (lastHistory && !lastHistory.is_viewed) {
                    dispatch('updateViewedLastHistory', lastHistory.id);
                }
            }
        },
        async fetchHistoryItem({ state, dispatch, getters }, historyId) {
            try {
                const { studentId, activityId } = getters;
                const historyItemInList = state.historyList.find(item => item.id === historyId);

                if (historyItemInList?.deleted_at) return;

                const response = await fetchHomeworkHistoryItem({
                    activityId,
                    studentId,
                    historyId,
                });

                const historyItem = response.data.data;

                return dispatch('addOrUpdateHistoryListItem', historyItem);
            } catch (e) {
                if (axios.isAxiosError(e) && e.response?.status === ClientErrorCodes.NOT_FOUND) {
                    dispatch('removeHistoryListItem', historyId);

                    return 'deleted';
                }

                console.error(e);
                throw e;

            }
        },
        async fetchHomework({ commit, getters }, { withUpdateListItem, activityId } = {}) {
            const { studentId } = getters;

            if (!activityId || !studentId) {
                commit('setHomework', {});

                return;
            }

            try {
                const { data: responseData } = await fetchHomeworkData({
                    activityId,
                    studentId,
                });

                commit('setHomework', responseData.data);

                if (withUpdateListItem) {
                    commit('tasks/updateHomeworkListItem', responseData.data, { root: true });
                }
            } catch {
                throw new Error('fetchHomework: Ошибка получения ДЗ');
            }
        },
        async changeCurrentHomeworkStatus({ dispatch, state, getters }, { status }) {
            const { studentId, activityId } = getters;
            const responseId = state.editableItem?.id;
            const requestBody = generateBaseRequestBody(state, {
                data: {
                    status,
                },
            });

            const res = await dispatch(
                'requestWithListLoader',
                async () => {
                    const response = await createHomeworkResponse({
                        ...requestBody,
                        activityId,
                        studentId,
                    });

                    dispatch('addOrUpdateHistoryListItem', response.data.data);

                    if (responseId) {
                        dispatch('fetchHistoryItem', responseId);
                    }

                    return response;
                },
            );

            return res;
        },

        async cancelAndCreateHomeworkResponse({ dispatch, getters, state }, { status, responseItem }) {
            const { studentId, activityId } = getters;
            const responseId = responseItem?.id || getters.editableItem?.historyItem.id;
            const requestBody = generateBaseRequestBody(state, {
                status,
                responseId,
                data: {
                    new_status: status,
                },
            });

            const res = await dispatch(
                'requestWithListLoader',
                async () => {
                    const response = await cancelAndCreatHomeworkResponse({
                        ...requestBody,
                        activityId,
                        studentId,
                    });

                    dispatch('addOrUpdateHistoryListItem', response.data.data);
                    dispatch('fetchHistoryItem', responseId);

                    return response;
                },
            );

            return res;
        },
        async updateHomeworkResponse({ dispatch, getters, state }) {
            const { studentId, activityId } = getters;
            const responseId = getters.editableItem.historyItem.id;
            const requestBody = generateBaseRequestBody(state, {
                responseId,
            });

            const res = await dispatch(
                'requestWithListLoader',
                async () => {
                    const response = await updateHomeworkResponse({
                        ...requestBody,
                        activityId,
                        studentId,
                    });

                    dispatch('addOrUpdateHistoryListItem', response.data.data);

                    return response;
                },
            );

            return res;
        },
        async addHomeworkAnswerByTeacher({ dispatch, getters }, { data, type, status, comment }) {
            const { studentId, activityId } = getters;

            const params = {
                activityId,
                studentId,
                data: {
                    [type]: data,
                    status,
                    comment,
                },
            };

            const res = await dispatch(
                'requestWithListLoader',
                async () => {
                    const response = await createHomeworkAnswerByTeacher(params);

                    dispatch('addOrUpdateHistoryListItem', response.data.data);

                    return response;
                },
            );

            return res;
        },
        async addHomeworkFiles({ getters }, { files, onProgress, cancelToken }) {
            const responseArray = [];
            let rejectedFiles = 0;

            for await (const file of files) {
                try {
                    validateFile(file, {
                        maxSize: MAX_FILE_SIZE_IN_BYTES,
                    });

                    const { data } = await createFile({
                        modelId: getters.homeworkId,
                        modelType: 'homework',
                        file,
                    }, {
                        cancelToken,
                        onUploadProgress: event => {
                            onProgress?.(event, file);
                        },
                    });

                    responseArray.push(data.data);
                } catch {
                    rejectedFiles++;
                }
            }

            return {
                responseArray,
                rejectedFiles,
            };
        },
        async requestWithListLoader({ dispatch, getters }, request) {
            const { activityId } = getters;

            try {
                await dispatch('showListLoader');

                const { data } = await request();

                await dispatch('fetchHomework', {
                    withUpdateListItem: true,
                    activityId,
                });

                dispatch('resetEditableItem');

                return data;
            } catch (e) {
                console.error(e);
                throw e;
            } finally {
                await dispatch('hideListLoader');
            }
        },
        async startTaskCheckingSession({ commit, dispatch, getters }) {
            const { activityId, studentId } = getters;

            if (!activityId || !studentId) return;

            dispatch('showCheckingLoader');

            try {
                const { data } = await startHomeworkCheckingSession({
                    activityId,
                    studentId,
                });

                commit('setHomework', data.data);

                if (getters.lastVersion) {
                    dispatch('fetchHistoryItem', getters.lastVersion.id);
                }

                if (getters.lastResponse) {
                    dispatch('fetchHistoryItem', getters.lastResponse.id);
                }
            } catch (error) {
                return errorHandler(error, 'startTaskCheckingSession', 'Ошибка начала сессии проверки ДЗ');
            } finally {
                dispatch('hideCheckingLoader');
            }
        },
        async prolongTaskCheckingSession({ getters }) {
            const { activityId, studentId } = getters;

            if (!activityId || !studentId) return;

            try {
                const response = await prolongHomeworkCheckingSession({
                    activityId,
                    studentId,
                });

                throwIf(!response.data.success, 'Не удалось продолжить сессию проверки');
            } catch (error) {
                return errorHandler(error, 'prolongTaskCheckingSession', 'Ошибка продолжения сессии проверки ДЗ');
            }
        },
        async finishTaskCheckingSession({ commit, getters }) {
            const { activityId, studentId } = getters;

            if (!activityId || !studentId) return;

            try {
                const { data } = await finishHomeworkCheckingSession({
                    activityId,
                    studentId,
                });

                commit('setHomework', data.data);
                commit('setCurrentSession', null);

                commit('tasks/updateHomeworkListItem', data.data, { root: true });

                throwIf(!data.success, 'Не удалось завершить сессию проверки');
            } catch (error) {
                return errorHandler(error, 'finishTaskCheckingSession', 'Ошибка завершения сессии проверки ДЗ');
            }
        },
        async getCurrentSessionData({ commit, getters }) {
            const { activityId, studentId } = getters;

            if (!activityId || !studentId) return;

            if (!studentId) return;

            try {
                const { data } = await getHomeworkCheckingSession({
                    activityId,
                    studentId,
                });

                commit('setCurrentSession', data.data);
            } catch (error) {
                return errorHandler(error, 'getCurrentSessionData', 'Ошибка получения сессии проверки ДЗ');
            }
        },
        updateActiveHomework({ commit }, { homework, activityId }) {
            const homeworkId = homework.homework?.id;
            const studentId = homework.performer_data?.id;

            commit('setHomeworkId', homeworkId);
            commit('setStudentId', studentId);
            commit('setActivityId', activityId);
            commit('setHomework', homework);
        },
        setHomeworkCheckingStatus({ getters, commit }, status) {
            const targetHomework = {
                ...getters.homework,
                status,
            };

            commit('setHomework', targetHomework);
        },
        showListLoader({ commit }) {
            commit('setLoaders', {
                name: 'list',
                show: true,
            });
        },
        showNextLoader({ commit }) {
            commit('setLoaders', {
                name: 'next',
                show: true,
            });
        },
        showCheckingLoader({ commit }) {
            commit('setLoaders', {
                name: 'checking',
                show: true,
            });
        },
        hideCheckingLoader({ commit }) {
            commit('setLoaders', {
                name: 'checking',
                show: false,
            });
        },
        hideListLoader({ commit }) {
            commit('setLoaders', {
                name: 'list',
                show: false,
            });
        },
        hideNextLoader({ commit }) {
            commit('setLoaders', {
                name: 'next',
                show: false,
            });
        },
        setEditable({ dispatch, commit }, item) {
            if (item.type === HOMEWORK_HISTORY_ITEM_TYPES.FILES_VERSION) {
                commit('setRelatedItem', null);
                commit('setEditableMessage', null);
                commit('setEditableVersionFiles', item);
            } else {
                dispatch('setEditableMessage', item);
            }
        },
        setEditableMessage({ commit }, value) {
            commit('setRelatedItem', null);
            commit('setEditableMessage', value);
        },
        setRelatedItem({ commit }, value) {
            commit('setEditableMessage', null);
            commit('setRelatedItem', value);
        },
        async sendMessage({ dispatch, getters }, { message }) {
            const { relatedItem, studentId, activityId } = getters;

            try {
                const { data } = await sendMessage({
                    activityId,
                    studentId,
                    data: {
                        message,
                        related_message: relatedItem?.id,
                    },
                });

                dispatch('addHistoryListItem', data.data);
                dispatch('setRelatedItem', null);
            } catch (error) {
                return errorHandler(error, 'sendMessage', 'Ошибка отправления сообщения');
            }
        },
        async editMessage({ dispatch, getters }, { message }) {
            const { editableMessage, studentId, activityId } = getters;

            try {
                const { data } = await editMessage({
                    activityId,
                    studentId,
                    message,
                    messageId: editableMessage.id,
                });

                dispatch('updateHistoryListItem', data.data);
                dispatch('setEditableMessage', null);
            } catch (error) {
                return errorHandler(error, 'editMessage', 'Ошибка редактирования сообщения');
            }
        },
        async editVersion({ dispatch, getters }, versionData) {
            const { editableMessage, studentId, activityId } = getters;

            try {
                const { versionId, essay, files } = versionData;
                const { data } = await updateHomeworkAnswerByTeacher({
                    activityId,
                    studentId,
                    versionId: editableMessage?.id ?? versionId,
                    data: {
                        essay,
                        files,
                    },
                });

                dispatch('updateHistoryListItem', data.data);
                dispatch('setEditableMessage', null);
            } catch (error) {
                return errorHandler(error, 'editVersion', 'Ошибка редактирования');
            }
        },
        async editResponse({ dispatch, getters }, messageData) {
            const { editableMessage, studentId, activityId } = getters;

            try {
                const { comment } = messageData;
                const { data } = await updateHomeworkResponse({
                    activityId,
                    studentId,
                    responseId: editableMessage.id,
                    data: {
                        comment,
                    },
                });

                dispatch('updateHistoryListItem', data.data);
                dispatch('setEditableMessage', null);
            } catch (error) {
                return errorHandler(error, 'editResponse', 'Ошибка редактирования');
            }
        },
        async deleteMessage({ dispatch, getters }, { messageId }) {

            try {
                const { studentId, activityId } = getters;

                await deleteMessage({
                    activityId,
                    studentId,
                    messageId,
                });

                dispatch('removeHistoryListItem', messageId);
            } catch (error) {
                return errorHandler(error, 'deleteMessage', 'Ошибка удаления сообщения');
            }
        },
        addOrUpdateHistoryListItem({ getters, dispatch }, historyItem) {
            const { historyList } = getters;
            const itemInList = historyList.find(item => item.id === historyItem.id);

            if (itemInList) {
                dispatch('updateHistoryListItem', historyItem);

                return 'updated';
            // проверяем что это новый элемет.
            // в противном случае это не новый и пользователь еще не проскролил до него
            } else if (historyList.length > 0 && historyItem.id > historyList[0].id) {
                dispatch('addHistoryListItem', historyItem);

                return 'added';
            }
        },
        addHistoryListItem({ getters, commit }, historyItem) {
            const { historyList } = getters;

            commit('setHistoryList', [
                ...historyList,
                historyItem,
            ]);
        },
        updateHistoryListItem({ commit, getters }, historyItem) {
            const { historyList } = getters;
            const newHistory = historyList.map(item => (item.id === historyItem.id ? historyItem : item));

            commit('setHistoryList', newHistory);
        },
        removeHistoryListItem({ commit, getters }, historyId) {
            const { historyList } = getters;

            const newHistory = historyList.map(item => (item.id === historyId
                ? {
                    ...item,
                    deleted_at: new Date().toISOString(),
                }
                : item));

            commit('setHistoryList', newHistory);
        },

        /**
         * @param {*} ctx
         * @param {object} payload
         * @param {FileList} payload.files
         * @param {Function} [payload.onAdded]
         */
        async addFilesToVersionFiles({ commit, dispatch, state }, { files, onAdded }) {
            /* eslint-disable no-useless-catch */
            try {
                commit('setLoaders', {
                    name: 'uploadingEditFiles',
                    show: true,
                });

                commit('changeEditableFiles', {
                    addedFiles: Array.from(files).map(file => getFileData(file)),
                });

                onAdded?.();

                const result = await dispatch('addHomeworkFiles', {
                    files,
                    onProgress: (progressEvent, file) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                        commit('updateAddedEditableFileProgress', {
                            file,
                            progress,
                        });
                    },
                });

                commit('changeEditableFiles', {
                    files: [...state.editableVersionFiles.files, ...result.responseArray],
                    addedFiles: [],
                });

                return result;
            } catch (e) {
                throw e;
            } finally {
                commit('setLoaders', {
                    name: 'uploadingEditFiles',
                    show: false,
                });
            }
            /* eslint-enable no-useless-catch */
        },

        async saveEditableVersionFiles({ dispatch, commit, state, getters }) {
            try {
                commit('setLoaders', {
                    name: 'savingEditFiles',
                    show: true,
                });
                this.isSaving = true;
                await dispatch('editVersion', {
                    homeworkId: getters.homeworkId,
                    versionId: state.editableVersionFiles.item.id,
                    files: getters.editableVersionFiles.map(file => file.id),
                });
            } catch (e) {
                console.error(e);
                throw e;
            } finally {
                commit('setLoaders', {
                    name: 'savingEditFiles',
                    show: false,
                });
            }
        },

        deleteEditingVersionFile({ commit, state }, fileItem) {
            commit('changeEditableFiles', {
                files: state.editableVersionFiles.files.filter(item => item !== fileItem),
                addedFiles: state.editableVersionFiles.addedFiles.filter(item => item !== fileItem),
            });
        },

        clearEditableVersionFile({ commit, getters }) {
            if (getters.shouldSaveVersionFiles) {
                commit('setModals', {
                    name: 'confirmCancelEditFiles',
                    show: true,
                });

                return false;
            }

            commit('clearEditableVersionFile');

            return true;

        },
    },
};
