import isEqual from 'lodash/isEqual.js';
import { getUserEditorColors } from '@/api/editorApi.js';

const findAnchors = children => {
    const list = [];

    children.forEach(item => {
        if (item.mark?.type?.name === 'anchor') {
            list.push(item.mark.attrs?.id || item.mark.dom?.id);
        }

        if (item.children?.length) {

            const innerList = findAnchors(item.children);

            if (innerList.length) {
                list.push(...innerList);
            }
        }
    });

    return list;
};

export default {
    namespaced: true,
    state: {
        userColors: [],
        textAnchors: {},
        removedAttachments: [],
        pageEditors: {},
    },
    getters: {
        blockEditorInstances: state => id => state.pageEditors[id],
        textAnchors: state => Object.keys(state.textAnchors).reduce(
            (accum, item) => accum.concat(state.textAnchors[item]),
            [],
        ),
        userColors: state => state.userColors,
        blocksAnchors: (state, getters, globalState, globalGetters) => {
            const { activities } = globalGetters['longread/longreadStructure'];

            if (!activities) return [];

            return activities.map(item => ({
                name: `ID ${item.id}${item.name ? ` — ${item.name}` : ''}`,
                id: `activity_${item.id}`,
            }));
        },
    },
    mutations: {
        setFavoriteColors(state, colors) {
            state.userColors = colors;
        },
        setAnchorsList(state, { anchors, key }) {
            state.textAnchors[key] = anchors;
        },
        setRemovedAttachment(state, attachments) {
            state.removedAttachments = attachments;
        },
        setEditorInstanceById(state, { blockId, editor }) {
            state.pageEditors[blockId] = state.pageEditors[blockId]
                ? [...state.pageEditors[blockId], editor]
                : [editor];
        },
    },
    actions: {
        async getUserFavoriteColors({ commit }) {
            try {
                const { data } = await getUserEditorColors();

                if (data.success) {
                    commit('setFavoriteColors', data.data);
                }
            } catch (error) {
                throw new Error(error);
            }
        },
        getAnchorsFromEditorInstance({ commit }, editor) {
            if (!editor?.view?.docView) return;

            commit('setAnchorsList', {
                anchors: findAnchors(editor.view.docView.children),
                key: editor.view.props.id,
            });
        },
        addToRemovedAttachment({ commit, state, getters }, data) {
            if (getters.isRemovedAttachment(data)) return;

            commit('setRemovedAttachment', [...state.removedAttachments, data]);
        },
        deleteFromRemovedAttachment({ commit, state, getters }, data) {
            if (!getters.isRemovedAttachment(data)) return;

            commit(
                'setRemovedAttachment',
                state.removedAttachments.filter(item => !isEqual(item, data)),
            );
        },
        addEditorInstance({ commit }, { blockId, editor }) {
            commit('setEditorInstanceById', {
                blockId,
                editor,
            });
        },
    },
};
