import icons from '@/admin/store/modules/icons.js';
import link from '@/admin/store/modules/link.js';
import longread from '@/admin/store/modules/longread.js';
import solutions from '@/admin/store/modules/solutions/index.js';
import events from '@/admin/store/modules/events/index.js';
import solutionsStructure from '@/admin/store/modules/solutionsStructure/index.js';
import developmentCenters from '@/admin/store/modules/developmentCenters/index.js';
import search from '@/admin/store/modules/search/index.js';
import tasks from '@/admin/store/modules/tasks/index.js';
import homeworkReviewModal from '@/admin/store/modules/homeworkReviewModal.js';
import homeworkReviewModalGroup from '@/admin/store/modules/homeworkReviewModalGroup.js';
import modals from './modules/modals.js';
import tags from './modules/tags.js';
import reports from './modules/reports.js';
import audio from './modules/audio.js';
import video from './modules/video.js';
import attendanceEvents from './modules/attendanceEvents.js';
import AI from './modules/AI.js';
import student from './modules/student.js';
import glossary from './modules/glossary.js';

export default {
    icons,
    link,
    longread,
    solutions,
    solutionsStructure,
    developmentCenters,
    modals,
    tags,
    search,
    reports,
    tasks,
    audio,
    video,
    homeworkReviewModal,
    homeworkReviewModalGroup,
    attendanceEvents,
    events,
    AI,
    student,
    glossary,
};
