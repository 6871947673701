<template>
    <OptionsPopup
        class="HomeworkActionPopup"
        :modelValue="modelValue"
        :popupContent="popupContent"
        offset="15"
        position="bottom-end"
        @event:forward="backwardEvent"
        @update:modelValue="handlePopupUpdate">
        <slot></slot>
    </OptionsPopup>
</template>

<script>
import blockIcon from '@/assets/svg/icons8-health-data.svg';
import fileIcon from '@/assets/svg/icons8-attach.svg';
import exportIcon from '@/assets/svg/icons8-block/icons8-test-passed.svg';
import templateIcon from '@/assets/svg/icons8-block/icons8-web-design.svg';
import useSnackbar from '@/hooks/snackbars.js';
import { startExportingReports, startImportingAllSolutions } from '@/admin/store/modules/reports.js';
import { getImportingStudentsTemplate } from '@/api/homeworkApi.js';
import { downloadUrl } from '@/api/attachmentsApi.js';
import OptionsPopup from '@/components/UI/OptionsPopup.vue';

const ACTION_HANDLER = {
    GO_TO_BLOCK: 'goToBlock',
    IMPORT_ALL_SOLUTIONS: 'importAllSolutions',
    EXPORT_REPORTS: 'exportReports',
    DOWNLOAD_TEMPLATE: 'downloadTemplate',
};

const ACTION_MENU = {
    GO_TO_BLOCK: {
        id: 1,
        type: 'item',
        text: 'Перейти к блоку',
        iconInline: blockIcon,
        handler: ACTION_HANDLER.GO_TO_BLOCK,
    },
    IMPORT_ALL_SOLUTIONS: {
        id: 2,
        type: 'upload',
        text: 'Импорт всех решений',
        iconInline: fileIcon,
        handler: ACTION_HANDLER.IMPORT_ALL_SOLUTIONS,
        fileExtensions: ['.xlsx', '.xls'],
    },
    EXPORT_REPORTS: {
        id: 3,
        type: 'item',
        text: 'Экспорт отчетов',
        iconInline: exportIcon,
        handler: ACTION_HANDLER.EXPORT_REPORTS,
    },
    DOWNLOAD_TEMPLATE: {
        id: 4,
        type: 'item',
        text: 'Скачать шаблон',
        iconInline: templateIcon,
        handler: ACTION_HANDLER.DOWNLOAD_TEMPLATE,
    },
    DIVIDER: {
        id: 5,
        type: 'divider',
    },
};

export default {
    name: 'HomeworkActionPopup',
    components: {
        OptionsPopup,
    },
    emits: ['update:modelValue', 'goToBlock'],
    computed: {
        popupContent() {
            return [
                ACTION_MENU.GO_TO_BLOCK,
                ACTION_MENU.DIVIDER,
                ACTION_MENU.IMPORT_ALL_SOLUTIONS,
                ACTION_MENU.EXPORT_REPORTS,
                ACTION_MENU.DIVIDER,
                ACTION_MENU.DOWNLOAD_TEMPLATE,
            ];
        },
    },
    methods: {
        handlePopupUpdate(value) {
            this.$emit('update:modelValue', value);
        },
        goToBlock() {
            this.$emit('goToBlock');
        },
        importAllSolutions(file) {
            const { homeworkId } = this.$route.params;
            const fileData = new FormData();

            fileData.append('file', file[0], file[0]?.name);

            startImportingAllSolutions(homeworkId, fileData);
        },
        exportReports() {
            const { homeworkId } = this.$route.params;

            startExportingReports(homeworkId);
        },
        downloadTemplate() {
            const { homeworkId } = this.$route.params;
            const fileUrl = getImportingStudentsTemplate(homeworkId);

            downloadUrl(fileUrl, '');
        },
        backwardEvent(event, file) {
            switch (event) {
                case ACTION_HANDLER.GO_TO_BLOCK: {
                    this.goToBlock();
                    break;
                }
                case ACTION_HANDLER.IMPORT_ALL_SOLUTIONS: {
                    this.importAllSolutions(file);
                    break;
                }
                case ACTION_HANDLER.EXPORT_REPORTS: {
                    this.exportReports();
                    break;
                }
                case ACTION_HANDLER.DOWNLOAD_TEMPLATE: {
                    this.downloadTemplate();
                    break;
                }
                default: {
                    break;
                }
            }
        },
    },
    setup() {
        const { createSnackbar } = useSnackbar();

        return {
            createSnackbar,
        };
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
