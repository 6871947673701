import icons8TextBlock from '@/assets/svg/icons8-block/icons8-text 1.svg';
import iconst8AccordBlock from '@/assets/svg/icons8-block/icons8-survey 5.svg';
import iconst8TabsBlock from '@/assets/svg/icons8-block/icons8-показать-все-виды 2.svg';
import icons8TextIconsBlock from '@/assets/svg/icons8-block/icons8-text-icon.svg';
import icons8QuestionBlock from '@/assets/svg/icons8-block/icons8-вопросы 2.svg';
import icons8PeopleBlock from '@/assets/svg/icons8-block/icons8-user-menu-male 2.svg';
import icons8QuoteBlock from '@/assets/svg/icons8-block/icons8-quote 2.svg';
import icons8flashCard from '@/assets/svg/icons8-block/Rotate - Outline.svg';
import icons8GaleryBlock from '@/assets/svg/icons8-block/icons8-image-gallery.svg';
import icons8TextPicturesBlock from '@/assets/svg/icons8-block/icons8-text-image.svg';
import icons8VideoBlock from '@/assets/svg/icons8-block/icons8-video-playlist 2.svg';
import icons8FileBlock from '@/assets/svg/icons8-block/icons8-file 2.svg';
import icons8TestBlock from '@/assets/svg/icons8-block/icons8-tick-box.svg';
import icons8LinkBlock from '@/assets/svg/icons8-block/icons8-link.svg';
import icons8HomeworkFilesBlock from '@/assets/svg/icons8-block/icon8-homework-files.svg';
import icons8EditFilledText from '@/assets/svg/icons8-block/icons8-edit-filled-text.svg';
import icons8Audio from '@/assets/svg/icons8-block/icons8-audio.svg';
import iconsEvent from '@/assets/svg/icons8-block/icons-calendar.svg';
import moduleIcon from '@/assets/svg/icons8-tree-structure2.svg';
import solutionIcon from '@/assets/svg/icons8-graduation-cap.svg';
import versionIcon from '@/assets/svg/icons8-toolbox.svg';
import sectionIcon from '@/assets/svg/icons8-layers.svg';
import pageIcon from '@/assets/svg/icons8-page-education2.svg';
import activityIcon from '@/assets/svg/icons8-health-data.svg';
import personalDataIcon from '@/assets/svg/icons8-contact.svg';
import GraduationCapIcon from '@/assets/svg/icon-graduation-cap.svg';
import ReportIcon from '@/assets/svg/icons8-estimates.svg';

export const LOCATION_ITEMS = {
    DEV_CENTER: 'Центр разработки',
    SOLUTION: 'Программа',
    VERSION: 'Поток',
    MODULE: 'Модуль',
    SECTION: 'Раздел',
    PAGE: 'Страница',
    ACTIVITY: 'Блок',
};

export const LOCATION_ITEMS_ICONS = {
    [LOCATION_ITEMS.SOLUTION]: solutionIcon,
    [LOCATION_ITEMS.VERSION]: versionIcon,
    [LOCATION_ITEMS.MODULE]: moduleIcon,
    [LOCATION_ITEMS.SECTION]: sectionIcon,
    [LOCATION_ITEMS.PAGE]: pageIcon,
    [LOCATION_ITEMS.ACTIVITY]: activityIcon,
};

export const SCROLL_TOP_STORAGE_KEY = 'sbs-scroll-top';

export const PAGE_STATUSES = Object.freeze({
    DRAFT: 'draft',
    PUBLISHED: 'published',
    SAVED: 'saved',
    ARCHIVED: 'archived',
    DELETED: 'deleted',
});

export const VERSION_STATUSES = Object.freeze({
    DRAFT: 'draft',
    PUBLISHED: 'published',
    SAVED: 'saved',
    ARCHIVED: 'archived',
});

export const USER_ROLES = Object.freeze({
    ADMIN: 'admin',
    MANAGER: 'manager',
    USER: 'user',
});

export const TEXT_WITH_ICONS_STYLE_NAMES = Object.freeze({
    UNIQUE: 'unique',
    WITH_BORDER: 'withBorder',
    LIGHT_BACKGROUND: 'lightBackground',
    DARK_BACKGROUND: 'darkBackground',
    IMAGE: 'image',
});


export const ACTIVITY_BLOCK_TYPES = Object.freeze({
    File: 'ActivityFile',
    Link: 'ActivityLink',
    Video: 'ActivityVideo',
    Audio: 'ActivityAudio',
    Accordion: 'ContentAccordion',
    ColumnsInFrames: 'ContentColumnsInFrames', // TODO удалить
    ImageSlider: 'ContentImageSlider',
    Tabs1: 'ContentTabs1',
    Text: 'ContentText',
    TextImage: 'ContentTextImage',
    TextWithIcons: 'ContentTextWithIcons',
    FAQ: 'QuestionAnswer',
    People: 'ContentPeople',
    Quote: 'ContentQuote',
    TestOrQuiz: 'TestOrQuiz',
    FlashCards: 'FlashCards',
    HomeworkFiles: 'HomeworkFiles',
    EssayHomework: 'EssayHomework',
    Event: 'ActivityEvent',
    PersonalData: 'PersonalData',
});

export const SUPPORTED_BLOCK_TYPES = [
    ACTIVITY_BLOCK_TYPES.Text,
    ACTIVITY_BLOCK_TYPES.Video,
    ACTIVITY_BLOCK_TYPES.TextImage,
    ACTIVITY_BLOCK_TYPES.HomeworkFiles,
    ACTIVITY_BLOCK_TYPES.File,
    ACTIVITY_BLOCK_TYPES.Link,
    ACTIVITY_BLOCK_TYPES.Audio,
    ACTIVITY_BLOCK_TYPES.Accordion,
    ACTIVITY_BLOCK_TYPES.ImageSlider,
    ACTIVITY_BLOCK_TYPES.Tabs1,
    ACTIVITY_BLOCK_TYPES.TextWithIcons,
    ACTIVITY_BLOCK_TYPES.FAQ,
    ACTIVITY_BLOCK_TYPES.People,
    ACTIVITY_BLOCK_TYPES.Quote,
    ACTIVITY_BLOCK_TYPES.TestOrQuiz,
    ACTIVITY_BLOCK_TYPES.FlashCards,
    ACTIVITY_BLOCK_TYPES.EssayHomework,
    ACTIVITY_BLOCK_TYPES.Event,
    ACTIVITY_BLOCK_TYPES.PersonalData,
];

export const BLOCK_NAME_BY_TYPE = Object.freeze({
    [ACTIVITY_BLOCK_TYPES.File]: 'Файл',
    [ACTIVITY_BLOCK_TYPES.Link]: 'Ссылка',
    [ACTIVITY_BLOCK_TYPES.Video]: 'Видео',
    [ACTIVITY_BLOCK_TYPES.Audio]: 'Аудио',
    [ACTIVITY_BLOCK_TYPES.Accordion]: 'Аккордеон',
    [ACTIVITY_BLOCK_TYPES.ColumnsInFrames]: 'Колонки в рамках',
    [ACTIVITY_BLOCK_TYPES.ImageSlider]: 'Изображение',
    [ACTIVITY_BLOCK_TYPES.Tabs1]: 'Вкладки',
    [ACTIVITY_BLOCK_TYPES.Text]: 'Текст',
    [ACTIVITY_BLOCK_TYPES.TextImage]: 'Текст с изображением',
    [ACTIVITY_BLOCK_TYPES.TextWithIcons]: 'Список с иконками',
    [ACTIVITY_BLOCK_TYPES.FAQ]: 'Диалог',
    [ACTIVITY_BLOCK_TYPES.People]: 'Люди',
    [ACTIVITY_BLOCK_TYPES.Quote]: 'Цитата',
    [ACTIVITY_BLOCK_TYPES.TestOrQuiz]: 'Тесты и опросы',
    [ACTIVITY_BLOCK_TYPES.FlashCards]: 'Флеш-карточки',
    [ACTIVITY_BLOCK_TYPES.HomeworkFiles]: 'Практика: файл',
    [ACTIVITY_BLOCK_TYPES.EssayHomework]: 'Практика: эссе',
    [ACTIVITY_BLOCK_TYPES.Event]: 'Мероприятие',
    [ACTIVITY_BLOCK_TYPES.PersonalData]: 'Персональная информация',
});

export const BlockIcons = {
    [ACTIVITY_BLOCK_TYPES.Text]: icons8TextBlock,
    [ACTIVITY_BLOCK_TYPES.Accordion]: iconst8AccordBlock,
    [ACTIVITY_BLOCK_TYPES.Tabs1]: iconst8TabsBlock,
    [ACTIVITY_BLOCK_TYPES.TextWithIcons]: icons8TextIconsBlock,
    [ACTIVITY_BLOCK_TYPES.FAQ]: icons8QuestionBlock,
    [ACTIVITY_BLOCK_TYPES.People]: icons8PeopleBlock,
    [ACTIVITY_BLOCK_TYPES.Quote]: icons8QuoteBlock,
    [ACTIVITY_BLOCK_TYPES.FlashCards]: icons8flashCard,
    [ACTIVITY_BLOCK_TYPES.ImageSlider]: icons8GaleryBlock,
    [ACTIVITY_BLOCK_TYPES.TextImage]: icons8TextPicturesBlock,
    [ACTIVITY_BLOCK_TYPES.Video]: icons8VideoBlock,
    [ACTIVITY_BLOCK_TYPES.File]: icons8FileBlock,
    [ACTIVITY_BLOCK_TYPES.TestOrQuiz]: icons8TestBlock,
    [ACTIVITY_BLOCK_TYPES.Link]: icons8LinkBlock,
    [ACTIVITY_BLOCK_TYPES.HomeworkFiles]: icons8HomeworkFilesBlock,
    [ACTIVITY_BLOCK_TYPES.EssayHomework]: icons8EditFilledText,
    [ACTIVITY_BLOCK_TYPES.Audio]: icons8Audio,
    [ACTIVITY_BLOCK_TYPES.Event]: iconsEvent,
    [ACTIVITY_BLOCK_TYPES.PersonalData]: personalDataIcon,
};

export const TOOLTIP_ICON_NAMES = Object.freeze({
    REMOVE_ICON: 'iconRemove',
    MOVE_ICON: 'iconMove',
    CROP_ICON: 'iconCrop',
});

export const PREVIEW_MEDIA = Object.freeze({
    MOBILE: 'mobile',
    TABLET: 'tablet',
    DESKTOP: 'desktop',
});

export const DEBOUNCE_TIME = 500;
export const DEBOUNCE_TIME_CHANGE_ANSWER = 300;
export const THROTTLE_TIME = 300;
export const SEARCH_CATEGORY_FILTERS = [
    {
        label: 'Везде',
        field: null,
    },
    {
        label: 'Проекты',
        field: 'projects',
    },
    {
        label: 'Команда',
        field: 'members',
    },
    {
        label: 'Отчеты',
        field: 'reports',
    },
];

export const SEARCH_ITEM_TYPES = {
    PROJECT: 'project',
    SOLUTION: 'solution',
    MEMBER: 'member',
    REPORT: 'report',
};

export const SEARCH_ITEM_ICONS = {
    [SEARCH_ITEM_TYPES.PROJECT]: GraduationCapIcon,
    [SEARCH_ITEM_TYPES.REPORT]: ReportIcon,
};

export const TOKEN_STORAGE_KEY = 'SBS_AUTH_TOKEN';

export const COMPLETED_PROGRESS = 100;

export const VIDEO_MATERIAL_TYPES = {
    LINK: 'link',
    FILE: 'file',
};

export const COMPLETION_TYPES = {
    /**
     * Пройти все страницы
     */
    COMPLETED: 'completed',
    /**
     * Пройти обязательные блоки
     */
    MARKED: 'marked',
};

export const VERSION_FORMATS = {
    CUSTOM: 'custom',
    HYBRID: 'hybrid',
    SEQUENTIAL: 'sequential',
};

export const MODULE_CARD_TYPE = Object.freeze({
    MODULE: 'module',
    SECTION: 'section',
});


export const TASK_TYPES = {
    FILE: 'HomeworkFiles',
    ESSAY: 'EssayHomework',
    ONLINE: 'online',
    OFFLINE: 'offline',
};

export const VERIFICATION_FORMAT = {
    AUTO: 'auto',
    MANUAL: 'manual',
};

export const TASK_STATUSES = {
    INITIAL: 'initial',
    ACCEPTED: 'accepted',
    PASSED: 'passed',
    NEED_CHECKING: 'need_checking',
    REJECTED: 'rejected',
    CHECKING: 'on_checking',
    FAILURE: 'failure',
    RESET: 'reset',
};

export const CONTENT_QUANTITY_FILTER = [
    {
        label: 'Сначала популярные',
        value: 'popular',
    },
    {
        label: 'Сначала редко используемые',
        value: 'rarely',
    },
    {
        label: 'По алфавиту (А-Я)',
        value: 'a-z',
    },
    {
        label: 'По алфавиту (Я-А)',
        value: 'z-a',
    },
];

export const ATTENDANCE_STUDENT_STATUSES = {
    NOT_SELECTED: 'not_given',
    PARTICIPATED: 'participated',
    ABSENT: 'absent',

};

export const HOMEWORK_HISTORY_ITEM_TYPES = {
    RESPONSE: 'response',
    ESSAY_VERSION: 'essay_version',
    FILES_VERSION: 'files_version',
    MESSAGE: 'message',
};

export const APPOINTMENT_MANAGEMENT = {
    INDIVIDUAL_EXECUTION: 'all_students',
    GROUP_EXECUTION: 'student_group',
};

export const DEADLINE_TYPE = {
    RECOMMENDATION: 'recommendation',
    LOST_HALF_SCORES: 'lostHalfScores',
    LOST_ALL_SCORES: 'lostAllScores',
};

export const ACCESS_CONDITIONS_TYPES = Object.freeze({
    ALWAYS: 'always',
    DATETIME: 'by_time',
    AFTER: 'after',
});

export const IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
export const IMAGE_EXTENSION = ['jpeg', 'jpg', 'png', 'gif', 'svg'];
