/* eslint-disable camelcase */
import {
    MAX_HOURS,
    LIKERT_SCALE_TYPES,
    QUESTION_TYPES,
    REACTION_TYPES,
    VISIBILITY_MIN_ANSWERS,
    MIN_ANSWER_LEVEL, TestQuizTypes,
    DistributionCategoryFormats,
    DistributionCategoryCardFormats, VISIBILITY_SHUFFLE_ANSWERS, QuizCardFormats,
} from '@/components/Blocks/BlockTestOrQuiz/constants.js';

/**
 * @param {string} questioType
 * @returns {{
 *      likertType: (string|null),
 *      questionContent: (null|string),
 *      reactionType: string,
 *      minAnswersLevel: (null|number),
 *      isShaked: boolean,
 *      generalAnswerReaction: (null|object),
 *      withOther: (null|boolean),
 *      distribFormatCategory: string,
 *      distribFormatCard: string,
 * }}
 */
export const getDefaultQuestionOptions = ({ blockType, questionType }) => ({
    isShaked: blockType === TestQuizTypes.TEST && VISIBILITY_SHUFFLE_ANSWERS.includes(questionType),
    isAnswerOneByOne: false,
    minAnswersLevel: VISIBILITY_MIN_ANSWERS.includes(questionType) ? MIN_ANSWER_LEVEL : null, // null or percents
    likertType: questionType === QUESTION_TYPES.LIKERT_SCALE ? LIKERT_SCALE_TYPES.NUMBER : null,
    withOther: null, // boolean|null
    generalAnswerReaction: null, // null | { correct: string, error: string }
    reactionType: REACTION_TYPES.NONE,
    questionContent: null,
    answerComment: false,
    distribFormatCategory: DistributionCategoryFormats.TEXT,
    distribFormatCard: DistributionCategoryCardFormats.TEXT,
    quizCardFormat: QuizCardFormats.TEXT,
});

export const checkDuration = val => {
    if (val) {
        const result = /(\d+?):(\d+?)/.exec(val);

        if (result) {
            const [, hours, minutes] = result;

            if (Number(hours) > MAX_HOURS || (Number(hours) >= MAX_HOURS && Number(minutes) > 0)) {
                return false;
            }
        }
    }

    return true;
};
