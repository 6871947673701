export const EVENTS_TYPE = {
    ONLINE: 'online',
    OFFLINE: 'offline',
};

export const EVENTS_FORMATS = {
    [EVENTS_TYPE.ONLINE]: 'Онлайн',
    [EVENTS_TYPE.OFFLINE]: 'Очно',
};

export const EVENTS_PERIOD = {
    SINGLE: 'single',
    MULTIPLE: 'multiple',
};

export const TABS_EVENTS = [
    {
        label: 'Все',
        value: null,
    },
    {
        label: EVENTS_FORMATS[EVENTS_TYPE.ONLINE],
        value: EVENTS_TYPE.ONLINE,
    },
    {
        label: EVENTS_FORMATS[EVENTS_TYPE.OFFLINE],
        value: EVENTS_TYPE.OFFLINE,
    },
];

export const SORT_EVENTS = [
    {
        label: 'Сначала новые',
        value: 'newest',
    },
    {
        label: 'Сначала старые',
        value: 'oldest',
    },
];

export const DEFAULT_EVENTS_FILTER_PARAMS = Object.freeze({
    // sort: SORT_EVENTS[0].value, Отключено, пока не доработана логика сортировки на бэк.
    type: TABS_EVENTS[0].value,
    page: 1,
});
