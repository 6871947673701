import axios from 'axios';

/**
 * @type {{ [key: string]: import('axios').CancelTokenSource }}
 */
const tokens = {};

/**
 * @param {string} key
 * @returns {import('axios').CancelTokenSource}
 */
export const createCancelHttp = key => {
    if (tokens[key]) {
        tokens[key].cancel(`cancel ${key}`);
    }

    tokens[key] = axios.CancelToken.source();

    return tokens[key];
};

/**
 * @param {any} err
 * @returns {boolean}
 */
export const isHttpCancel = err => axios.isCancel(err);

/**
 * @param err
 * @returns {err is import('axios').AxiosError}
 */
export const isAxiosError = err => axios.isAxiosError(err);

/**
 * @param {import('axios').AxiosResponse<{ success: boolean, data: any }>} resp
 * @returns {boolean}
 * @throws {import('axios').AxiosResponse<{ success: boolean, data: any }>}
 */
export const checkResponseSuccess = resp => {
    if (!resp.data.success) throw resp;

    return true;
};
