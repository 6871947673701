import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

/**
 *
 * @param {object} options
 * @param {*} options.homeworkId
 * @param {*} options.page
 * @param {*} options.search
 * @param {*} options.new_message
 * @param {*} options.status
 * @param {Array<*>} options.mentors
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const getHomeworkBlockList = (options, config = {}) => {
    /* eslint-disable camelcase */
    const { homeworkId, page, search, new_message, status, mentors } = options;

    return axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${homeworkId}/student-groups`, {
        ...config,
        params: {
            page,
            search,
            new_message: new_message ? 1 : 0,
            status,
            mentors,
        },
    });
    /* eslint-enable camelcase */
};

/**
 * Получить домашнее задание
 * @param {object} params
 * @param {*} params.activityId
 * @param {*} params.performerId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchHomework = ({ activityId, performerId }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}`);

/**
 * Получить историю домашнего задания
 * @param {object} params
 * @param {*} params.activityId
 * @param {*} params.performerId
 * @param {*} params.cursor Хэш курсорной пагинации
 * @param {*} params.order
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchHomeworkHistory = ({ activityId, performerId, cursor, order }) => axios
    .get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}/history`, {
        params: {
            cursor,
            order,
        },
    });

/**
 * Начать сеccию проверки домашнего задания
 * @param {object} params
 * @param {*} params.activityId
 * @param {*} params.performerId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const startHomeworkCheckingSession = ({ activityId, performerId }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}/start-checking`);


/**
 * Закончить сеccию проверки домашнего заданияы
 * @param {object} params
 * @param {*} params.activityId
 * @param {*} params.performerId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const finishHomeworkCheckingSession = ({ activityId, performerId }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}/finish-checking`);

/**
 * Продлить сеccию проверки домашнего задания
 * @param {object} params
 * @param {*} params.activityId
 * @param {*} params.performerId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const prolongHomeworkCheckingSession = ({ activityId, performerId }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}/prolong-checking`);

/**
 * Получить сеccию проверки домашнего задания группы
 * @param {object} params
 * @param {*} params.activityId
 * @param {*} params.performerId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const getHomeworkCheckingSession = ({ activityId, performerId }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}/checking-session`);

/**
 * Получить элемент истории домашнего задания
 * @param {object} paraps
 * @param {*} paraps.activityId
 * @param {*} paraps.performerId
 * @param {*} paraps.historyId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchHomeworkHistoryItem = ({ activityId, performerId, historyId }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}/history/${historyId}`);

/**
 * Создать ответ для домашнего задания
 * @param {object} params
 * @param {*} params.activityId
 * @param {*} params.performerId
 * @param {*} params.data
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const createHomeworkResponse = ({ activityId, performerId, data }) => axios.post(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}/responses`,
    data,
);

/**
 * Обновить ответ домашнего задания
 * @param {object} options
 * @param {string|number} options.activityId
 * @param {string|number} options.performerId
 * @param {string|number} options.responseId
 * @param {object} options.data
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const updateHomeworkResponse = ({ activityId, performerId, responseId, data }) => axios.put(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}/responses/${responseId}`,
    data,
);

/**
 * Отменить ответ домашнего задания и создать новый
 * @param activityId
 * @param performerId
 * @param responseId
 * @param data
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const cancelAndCreatHomeworkResponse = ({ activityId, performerId, responseId, data }) => axios.post(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}/responses/${responseId}/cancel`,
    data,
);

/**
 * @param {object} params
 * @param {*} params.activityId
 * @param {*} params.performerId
 * @param {*} params.historyId
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const homeworkHistoryViewed = ({ activityId, performerId, historyId }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}/history/${historyId}/view`);

/**
 * Создать ответ домашнего задания от преподавателя
 * @param {object} params
 * @param {*} params.activityId
 * @param {*} params.performerId
 * @param data
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const createHomeworkAnswerByTeacher = ({ activityId, performerId, data }) => axios.post(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}}/student-groups/${performerId}/versions`,
    data,
);

/**
 * Обновить ответ домашнего задания от преподавателя
 * @param {object} options
 * @param {string|number} options.activityId
 * @param {string|number} options.performerId
 * @param {string|number} options.versionId
 * @param {object} options.data
 * @param {string} [options.data.essay]
 * @param {array} [options.data.files]
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const updateHomeworkAnswerByTeacher = ({ activityId, performerId, versionId, data }) => axios.put(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}}/student-groups/${performerId}/versions/${versionId}`,
    data,
);

/**
 * Отправить сообщение
 * @param activityId
 * @param performerId
 * @param data
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const sendMessage = ({ activityId, performerId, data }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}/messages`, data);

/**
 * Редактировать сообщение
 * @param activityId
 * @param performerId
 * @param messageId
 * @param message
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const editMessage = ({ activityId, performerId, messageId, message }) => axios.put(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}/messages/${messageId}`, { message });

/**
 * Удалить сообщение
 * @param activityId
 * @param performerId
 * @param messageId
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const deleteMessage = ({ activityId, performerId, messageId }) => axios.delete(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/student-groups/${performerId}/messages/${messageId}`);
