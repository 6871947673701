import AUTOIcon from '@/assets/svg/icons8-block/icons8-survey 4.svg';
import MANUALIcon from '@/assets/svg/icons8-filled-speech-bubble-info-emotion.svg';

export const FILTER_ASSIGNMENTS = [
    {
        label: 'Все',
        value: null,
    },
    {
        label: 'Файл',
        value: 'HomeworkFiles',
    },
    {
        label: 'Эссе',
        value: 'EssayHomework',
    },
];

export const SORT_ASSIGNMENTS = [
    {
        label: 'Сначала новые',
        value: 'newest',
    },
    {
        label: 'Сначала старые',
        value: 'oldest',
    },
];

export const TYPE_ACCEPTANCE = {
    AUTO: 'auto',
    MANUAL: 'manual',
};

export const ASSIGNMENT_TYPE = {
    GROUP: 'group',
    INDIVIDUAL: 'individual',
};

export const TYPE_ACCEPTANCE_INFO = {
    [TYPE_ACCEPTANCE.AUTO]: {
        label: 'Проверка системой',
        icon: AUTOIcon,
    },
    [TYPE_ACCEPTANCE.MANUAL]: {
        label: 'С преподавателем',
        icon: MANUALIcon,
    },
};

export const DEFAULT_MODAL_ASSIGNMENTS_FILTERS = Object.freeze({
    assignments: [],
    with_support: 0,
});

export const DEFAULT_VERSIONS_ASSIGNMENTS_PARAMS = Object.freeze({
    sort: SORT_ASSIGNMENTS[0].value,
    type: FILTER_ASSIGNMENTS[0].value,
    page: 1,
    ...DEFAULT_MODAL_ASSIGNMENTS_FILTERS,
});
