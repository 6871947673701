<template>
    <div class="solutions-clear-members d-flex flex-column align-items-center justify-content-center">
        <slot name="prepend"></slot>
        <slot name="icon">
            <img
                class="mb-2"
                :class="imageClasses"
                :src="icon">
        </slot>
        <div class="typo-primary-bold mb-1">
            <slot name="title">
                {{ label }}
            </slot>
        </div>
        <div class="solutions-clear-members__text typo-secondary-book">
            <slot name="description">
                {{ subLabel }}
            </slot>
        </div>
        <slot name="append"></slot>
    </div>
</template>

<script>
export default {
    name: 'EmptySearchStub',
    props: {
        icon: {
            type: String,
            default: '',
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        subLabel: {
            type: String,
            default: '',
        },
        imageClasses: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
.solutions-clear-members {
    margin: 25px 0;
    text-align: center;
}

.solutions-clear-members__text {
    max-width: 296px;
    color: #74777d;
}
</style>

